import React from 'react';
import {
    Grid,
    Button,
    Dialog,
    IconButton,
    withStyles,
    Typography
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';


const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles(theme => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  export function DrazticFlashDialog(data){

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    return(
      <Grid item>
            <Button onClick={handleClickOpen}>
                            <img className="thumbnails" src={data.thumbnail} alt="VerifyMobile" />
                            </Button>

                            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                                {data.title}
                                </DialogTitle>
                               
                               
                                <DialogContent dividers>
                                <Grid container justify="center">
                                    <Grid item>
                                    <embed src={data.swf}  height="400px" width="400px" />
                                    </Grid>
                                </Grid>
                                    <Typography gutterBottom>
                                        {data.text}
                                    </Typography>
                                </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleClose} color="secondary">
                                Close
                            </Button>
                        </DialogActions>     
                </Dialog>
        </Grid>
    );
    
  }