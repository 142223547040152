import React, { Component } from 'react';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import {ButtonAppBar} from './ButtonAppBar.js';
import {MobileApps} from './MobileApps.js';
import {Websites} from './Websites.js';
import {Logos} from './Logos.js';
import {PrintWork} from './PrintWork.js';
import {AnimationsFlash} from './AnimationsFlash.js';
import {AnimationsGif} from './AnimationsGif.js';
import {ComputerArt} from './3DArt.js';
import {Illustrations} from './Illustrations.js';
import { 
  createMuiTheme, 
  ThemeProvider, 
  Grid 
} from '@material-ui/core';
import {indigo, yellow} from '@material-ui/core/colors';
import './App.css';
import minilogo from './assets/javasite/images/minilogo.png';
import contact from './assets/Images5/contact.jpg';

const theme = createMuiTheme({
  palette: {
    primary: yellow,
    secondary: indigo
  },
});

class HomePage extends Component {
  render(){
      return (
        <Grid container spacing={1}>
          <MobileApps></MobileApps>
          <Websites></Websites>
          <Logos></Logos>
          <PrintWork></PrintWork>
          <AnimationsFlash></AnimationsFlash>
          <AnimationsGif></AnimationsGif>
          <ComputerArt></ComputerArt>
          <Illustrations></Illustrations>
          <Grid container justify="center" spacing={1}>
            <Grid item>
                <div id="about1" class="isoitem about-tope">
          
                <div class="blackbox"><div id="minilogo"><img src={minilogo} width="196" height="158" alt="DraZtic Mini Logo" /></div>
                    <div id="title4"><strong>ABOUT DRAZTIC.COM</strong></div>
                    <div id="abouttext" flex="" class="flex">
                  <p>Welcome to DraZtic.com. This website was created by myself as a portfolio for my artwork on the web.  Please feel free to look around the site. You will see many retail products and art work offering sales and services. These images, animations, web sites, apps, and graphics were created for clients and business associates for products and/or advertising services. <br />
                    <br />
                    These works, while designed by or collaborated on by myself, belong solely to their respective owners and are only being displayed on this site for demonstration/viewing purposes. Note that I have no control or input on the actual content and/or usage of these webpages and/or advertising materials, and the sites themselves aren't affiliated with DraZtic.com. </p>
                  <p>&nbsp;</p>
                  <p>All other artwork displayed on this site are copyrighted by myself (excluding fan based work). While you may see artwork based on other copyrighted characters (fanart) that I did not create, I myself do all the artwork. These particular works are strickly fan based and no money is made off of them.<br />
                <br />
                All images (unless stated otherwise) created by DraZtic, Copyright © 2020, All rights reserved
                Please do not use images without permission. All non original/fan art characters copyright their respective owners.
                <br />
                  </p>
                </div>
                </div>
            </div>
            </Grid>
          </Grid>
        </Grid>  
      );
  }
};

class MobileAppsPage extends Component {
  render(){
      return (
        <Grid container spacing={1}>
          <MobileApps></MobileApps>
        </Grid>  
      );
  }
};

class WebsitesPage extends Component {
  render(){
      return (
        <Grid container spacing={1}>
          <Websites></Websites>
        </Grid>  
      );
  }
};

class LogosPage extends Component {
  render(){
      return (
        <Grid container spacing={1}>
          <Logos></Logos>
        </Grid>  
      );
  }
};

class PrintWorkPage extends Component {
  render(){
      return (
        <Grid container spacing={1}>
          <PrintWork></PrintWork>
        </Grid>  
      );
  }
};

class AnimationsPage extends Component {
  render(){
      return (
        <Grid container spacing={1}>
          <AnimationsFlash></AnimationsFlash>
          <AnimationsGif></AnimationsGif>
        </Grid>  
      );
  }
};

class ComputerArtPage extends Component {
  render(){
      return (
        <Grid container spacing={1}>
          <ComputerArt></ComputerArt>
        </Grid>  
      );
  }
};

class IllustrationsPage extends Component {
  render(){
      return (
        <Grid container spacing={1}>
          <Illustrations></Illustrations>
        </Grid>  
      );
  }
};

class AboutPage extends Component {
  render(){
      return (
        <Grid container justify="center" spacing={1}>
        <Grid item>
          <div id="about1" class="isoitem about-tope">
      
            <div class="blackbox"><div id="minilogo"><img src={minilogo} width="196" height="158" alt="DraZtic Mini Logo" /></div>
                <div id="title4"><strong>ABOUT DRAZTIC.COM</strong></div>
                <div id="abouttext" flex="" class="flex">
              <p>Welcome to DraZtic.com. This website was created by myself as a portfolio for my artwork on the web.  Please feel free to look around the site. You will see many retail products and art work offering sales and services. These images, animations, web sites, apps, and graphics were created for clients and business associates for products and/or advertising services. <br />
                <br />
                These works, while designed by or collaborated on by myself, belong solely to their respective owners and are only being displayed on this site for demonstration/viewing purposes. Note that I have no control or input on the actual content and/or usage of these webpages and/or advertising materials, and the sites themselves aren't affiliated with DraZtic.com. </p>
              <p>&nbsp;</p>
              <p>All other artwork displayed on this site are copyrighted by myself (excluding fan based work). While you may see artwork based on other copyrighted characters (fanart) that I did not create, I myself do all the artwork. These particular works are strickly fan based and no money is made off of them.<br />
            <br />
            All images (unless stated otherwise) created by DraZtic, Copyright © 2020, All rights reserved
            Please do not use images without permission. All non original/fan art characters copyright their respective owners.
            <br />
              </p>
            </div>
            </div>
        </div>
       </Grid>
       </Grid>
      );
  }
};

class ContactPage extends Component {
  render(){
      return (
        <Grid container justify="center" spacing={1}>
        <Grid item>
          <div id="about1" class="isoitem about-tope">
      
            <div class="blackbox"><div id="minilogo"><img src={minilogo} width="196" height="158" alt="DraZtic Mini Logo" /></div>
                <div id="title4"><strong>Contact DRAZTIC.COM</strong></div>
                <div id="abouttext" flex="" class="flex">
              
                <img src={contact} alt="Contact DraZtic" />
            </div>
            </div>
        </div>
       </Grid>
       </Grid>
      );
  }
};

class NotFoundPage extends Component {
  render(){
      return (
        <Grid container justify="center" spacing={1}>
        <Grid item>
          <div id="about1" class="isoitem about-tope">
      
            <div class="blackbox"><div id="minilogo"><img src={minilogo} width="196" height="158" alt="DraZtic Mini Logo" /></div>
                <div id="title4"><strong>DRAZTIC</strong></div>
                <div id="abouttext" flex="" class="flex">
              <p>404, Page Not Found!
            <br />
            The requested URL was not found. 
              </p>
            </div>
            </div>
        </div>
       </Grid>
       </Grid>
      );
  }
};

function App() {

  let date = new Date();
  let year = date.getFullYear();

  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <header className="App-header">
      <ButtonAppBar></ButtonAppBar>
      </header>
      <main>

        <Router>
          <Switch>
            <Route path="/home" component={HomePage}/>
            <Route path="/apps" component={MobileAppsPage}/>
            <Route path="/websites" component={WebsitesPage}/>
            <Route path="/logos" component={LogosPage}/>
            <Route path="/print" component={PrintWorkPage}/>
            <Route path="/animation" component={AnimationsPage}/>
            <Route path="/3d" component={ComputerArtPage}/>
            <Route path="/illustration" component={IllustrationsPage}/>
            <Route path="/about" component={AboutPage}/>
             <Route path="/not-found" component={NotFoundPage} />
             <Route path="/contact" component={ContactPage} />
            <Redirect from="/" exact to="/home" />
            <Redirect to="/not-found" />
          </Switch>
          
        </Router>

      </main>
      <footer>
      <div id="bottombar">
<div id="bottomlinks" align="center"><span><a href="/">Home</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="/apps">Apps</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="/websites">Websites</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="/logos">Logos</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="/print">Print Work</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="/animation">Animation</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="/3d">3D Art</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="/about">About</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="/contact">Contact</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="/illustration">Illustration</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
</div>

      Copyright © 2001 - {year} by DraZtic ................. (All Rights Reserved)<br />
Web Page Created: November 14, 2001<br />
<br />
All images (unless stated otherwise) created by DraZtic. All rights reserved. All artwork offering products and/or services were created for clients and business associates for products and/or advertising services. I have no control/input on the actual content/usage of the artwork created for these clients, and they themselves aren't affiliated with DraZtic.com. This also includes company logos, marketing, and advertising materials which belong to their respective owners. Please do not use images without permission. All non original/fan art characters copyright their respective owners.
      </footer>
    </div>
    </ThemeProvider>
  );
}

export default App;
