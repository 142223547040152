import React from 'react';

import {DrazticWebsiteDialog} from './WebsiteDialog.js';

import th_cruisevoyant from './assets/Thumbs/th_cruisevoyant.jpg';
import th_shopforcruises from './assets/Thumbs/th_shopforcruises.jpg';
import th_jmfieldmarketing from './assets/Thumbs/th_jmfieldmarketing.jpg';
import th_lifecellskin from './assets/Thumbs/th_lifecellskin.jpg';
import th_rjcyachts from './assets/Thumbs/th_rjcyachts.jpg';
import th_gilman from './assets/Thumbs/th_gilman.jpg';
import th_dtf from './assets/Thumbs/th_dtf.jpg';
import th_fyba from './assets/Thumbs/th_fyba.jpg';           
import th_kenney1 from './assets/Thumbs/th_kenney1.jpg';
import th_fishzizzle from './assets/Thumbs/th_fishzizzle.jpg';
import th_dtf2 from './assets/Thumbs/th_dtf2.jpg';
import th_dermalift2 from './assets/Thumbs/th_dermalift2.jpg';
import th_dermalift from './assets/Thumbs/th_dermalift.jpg';
import th_Rejuvinx from './assets/Thumbs/th_Rejuvinx.jpg';
import th_onlineprofithouse from './assets/Thumbs/th_onlineprofithouse.jpg';
import th_nextjobus from './assets/Thumbs/th_nextjobus.jpg';
import th_nextjobcom from './assets/Thumbs/th_nextjobcom.jpg';
import th_maxcolo from './assets/Thumbs/th_maxcolo.jpg';
import th_getbrightwhites from './assets/Thumbs/th_getbrightwhites.jpg';
import th_acaimaxcleanse from './assets/Thumbs/th_acaimaxcleanse.jpg';
import th_MaximumBurn from './assets/Thumbs/th_MaximumBurn.jpg';
import th_nextjoborg from './assets/Thumbs/th_nextjoborg.jpg';
import th_igniteecig from './assets/Thumbs/th_igniteecig.jpg';
import th_petipaaws from './assets/Thumbs/th_petipaaws.jpg';
import th_coloblast from './assets/Thumbs/th_coloblast.jpg';
import th_nextjobathome from './assets/Thumbs/th_nextjobathome.jpg';
import th_nextjobinfo from './assets/Thumbs/th_nextjobinfo.jpg';
import th_viroplex from './assets/Thumbs/th_viroplex.jpg';
import th_InternetProfitVault from './assets/Thumbs/th_InternetProfitVault.jpg';
import th_colonature from './assets/Thumbs/th_colonature.jpg';
import th_acaimax from './assets/Thumbs/th_acaimax.jpg';
import th_everbrite from './assets/Thumbs/th_everbrite.jpg';
import th_vectracc from './assets/Thumbs/th_vectracc.gif';
import th_InternetProfits from './assets/Thumbs/th_InternetProfits.jpg';
import th_Paravol_Website from './assets/Thumbs/th_Paravol_Website.jpg';
import th_berryflush from './assets/Thumbs/th_berryflush.jpg';
import th_cylapril from './assets/Thumbs/th_cylapril.jpg';
import th_internetsuccessv from './assets/Thumbs/th_internetsuccessv.jpg';
import th_AFI from './assets/Thumbs/th_AFI.jpg';
import th_leviathandirect from './assets/Thumbs/th_leviathandirect.jpg';        
import th_vividwhitesmiles from './assets/Thumbs/th_vividwhitesmiles.jpg';
import th_melbec from './assets/Thumbs/th_melbec.gif';
import th_aspire36site from './assets/Thumbs/th_aspire36site.jpg';
import th_ResV from './assets/Thumbs/th_ResV.jpg';
import th_systemsandsignals from './assets/Thumbs/th_systemsandsignals.gif';
import th_paloalto from './assets/Thumbs/th_paloalto.jpg';
import th_ArcticSmile from './assets/Thumbs/th_ArcticSmile.jpg';
import th_maquicoloncleanse from './assets/Thumbs/th_maquicoloncleanse.jpg';
import th_acailean from './assets/Thumbs/th_acailean.jpg';
import th_equinascorp from './assets/Thumbs/th_equinascorp.gif';
import th_vasutrasite from './assets/Thumbs/th_vasutrasite.jpg';
import th_Nanoprenconcept from './assets/Thumbs/th_Nanoprenconcept.jpg';
import th_sohoStyle from './assets/Thumbs/th_sohoStyle.jpg';
import th_verifyIDWebsite from './assets/Thumbs/th_verifyIDWebsite.jpg';

const websiteData = [
    {
        thumbnail: th_sohoStyle,
        title: 'Soho Style',
        text: 'Soho Style. Luxury Hair Extensions (Human Hair) & Handcrafted Hair Accessories. Hair Accessory website created using Shopify CMS, Php, Javascript, HTML, CSS and Bronto Third Party Extensions.',
        url: 'http://www.sohostyle.com'
    },
    {
        thumbnail: th_verifyIDWebsite,
        title: 'Verify ID',
        text: 'Verify ID website created using Javascript, HTML, CSS, and PHP.  Uses Wordpress CMS and MYSQL Backend',
        url: 'http://www.verifyid.com'
    },
    {
        thumbnail: th_cruisevoyant,
        title: 'Cruise Voyant',
        text: 'Cruise Voyant: A Revolution In Cruise Booking. Cruise Booking website created using Javascript, HTML, CSS, and PHP.  Uses the Expression Engine CMS and MYSQL Backend.',
        url: 'https://web.archive.org/web/20140208000343/http://www.cruisevoyant.com/'
    },
    {
        thumbnail: th_shopforcruises,
        title: 'Shop For Cruises',
        text: 'Shop For Cruises: A Revolution In Cruise Booking. Cruise Booking website created using Javascript, HTML, CSS, and PHP.  Uses Expression Engine CMS and MYSQL Backend.',
        url: 'https://web.archive.org/web/20150820005514/http://www.shopforcruises.com/home'

    },
    {
        thumbnail: th_jmfieldmarketing,
        title: 'JM Field Marketing',
        text: 'JM Field Marketing. Website created using Wordpress, PHP, CSS, HTML, and Javascript. Prototyping done in Photoshop',
        url: 'http://www.jmfieldmarketing.com'
    },
    {
        thumbnail: th_lifecellskin,
        title: 'Life Cell Skin',
        text: 'Life Cell Skin website created using HTML, CSS, Javascript, PHP, and Magneto CMS',
        url: 'https://web.archive.org/web/20170201072802/http://www.lifecellskin.com/'
    },
    {
        thumbnail: th_rjcyachts,
        title: 'RJC Yachts',
        text: 'RJC Yachts website created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'http://www.rjcyachts.com'
    },
    {
        thumbnail: th_gilman,
        title: 'Gilman Yachts',
        text: 'Gilman Yachts website created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'http://www.gilmanyachts.com'
    },
    {
        thumbnail: th_dtf,
        title: 'DTF Yachts',
        text: 'DTF Yachts website created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'http://www.dtfyachts.com'
    },
    {
        thumbnail: th_fyba,
        title: 'FYBA',
        text: 'FYBA website created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/fyba/index.htm'
    },           
    {
        thumbnail: th_kenney1,
        title: 'Kenney Property',
        text: 'Kenney Property created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/kenney1/frame.html'
    },
    {
        thumbnail: th_fishzizzle,
        title: 'Fishzizzle',
        text: 'Fishzizzle created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'http://www.fishzizzle.com/'

    },
    {
        thumbnail: th_dtf2,
        title: 'DTF Yachts Ver. 1',
        text: 'DTF Yachts Ver. 1 created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/dtf2/index.html'
    },
    {
        thumbnail: th_dermalift2,
        title: 'Dermalift Platinum',
        text: 'Dermalift Platinum website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/dermaliftplatinum/index.html'
    },
    {
        thumbnail: th_dermalift,
        title: 'Dermalift Original',
        text: 'Dermalift Original website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/dermaliftoriginal/index.html'
    },
    {
        thumbnail: th_Rejuvinx,
        title: 'Rejuvinx',
        text: "Rejuvinx website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop",
        url: 'http://www.draztic.com/sites/rejuvinx/index.htm'
    },
    {
        thumbnail: th_onlineprofithouse,
        title: 'The Online Profit House',
        text: 'The Online Profit House website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/theonlineprofithouse/index.htm'
    },
    {
        thumbnail: th_nextjobus,
        title: 'Next In Home Jobs',
        text: 'Next In Home Jobs website created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'http://nextinhomejobs.us/'
    },
    {
        thumbnail: th_nextjobcom,
        title: 'Next In Home Jobs',
        text: 'Next In Home Jobs website created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'https://web.archive.org/web/20120601053036/http://www.nextinhomejobs.com/'
    },
    {
        thumbnail: th_maxcolo,
        title: 'Nutra Cleanse Blue',
        text: 'Nutra Cleanse Blue website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/nutracleanseblue/index.html'
    },
    {
        thumbnail: th_getbrightwhites,
        title: 'Get Bright Whites',
        text: 'Get Bright Whites website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/superwhite/index.html'
    },
    {
        thumbnail: th_acaimaxcleanse,
        title: 'Acai Max Cleanse',
        text: 'Acai Max Cleanse website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/acaimc/index.html'

    },
    {
        thumbnail: th_MaximumBurn,
        title: 'Maximum Burn Xtreme',
        text: 'Maximum Burn Xtreme website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/maximumburnxtreme/index.htm'
    },
    {
        thumbnail: th_nextjoborg,
        title: 'Next In Home Jobs',
        text: 'Next In Home Jobs website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'https://web.archive.org/web/20120720050711/http://nextinhomejobs.org/'
    },
    {
        thumbnail: th_igniteecig,
        title: 'Ignite E Cigarettes',
        text: 'Ignite E Cigarettes website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/igniteecigs/index.html'
    },
    {
        thumbnail: th_petipaaws,
        title: 'Petipaaws',
        text: 'Petipaaws website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/petipaaws/index.html'
    },
    {
        thumbnail: th_coloblast,
        title: 'Coloblast Xtreme',
        text: 'Coloblast Xtreme website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/coloblastxtreme/index.html'
    },
    {
        thumbnail: th_nextjobathome,
        title: 'Next Job At Home',
        text: 'Next Job At Home website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'https://web.archive.org/web/20121002030800/http://nextjobathome.com/'
    },
    {
        thumbnail: th_nextjobinfo,
        title: 'Next In Home Job',
        text: 'Next In Home Job website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'https://web.archive.org/web/20120106211530/nextinhomejobs.info/'
    },
    {
        thumbnail: th_viroplex,
        title: 'Viroplex',
        text: 'Viroplex website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/viroplex/index.html'
    },
    {
        thumbnail: th_InternetProfitVault,
        title: 'Internet Profit Vault',
        text: 'Internet Profit Vault website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/websuccess/index.htm'
    },
    {
        thumbnail: th_colonature,
        title: 'Colo Nature Cleanse',
        text: 'Colo Nature Cleanse website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/nutrabody/index.htm'
    },
    {
        thumbnail: th_acaimax,
        title: 'Fit Plus Formula',
        text: 'Fit Plus Formula website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/fitformula/index.html'
    },
    {
        thumbnail: th_everbrite,
        title: 'Everbrite',
        text: 'Everbrite website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/getbright/index.html'
    },
    {
        thumbnail: th_vectracc,
        title: 'Vectra Capital',
        text: 'Vectra Capital website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/vectracapital/index.html'
    },
    {
        thumbnail: th_InternetProfits,
        title: 'Internet Profit',
        text: 'Internet Profit website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/profithouse/index.html'
    },
    {
        thumbnail: th_Paravol_Website,
        title: 'Paravol',
        text: 'Paravol website created using HTML, CSS, Javascript. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/paravol/index.htm'
    },
    {
        thumbnail: th_berryflush,
        title: 'Acai Berry Flush',
        text: 'Acai Berry Flush website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/acaiflush/index.html'
    },
    {
        thumbnail: th_cylapril,
        title: 'Cylapril',
        text: 'Cylapril website created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/concepts/cylapril/index.htm'
    },
    {
        thumbnail: th_internetsuccessv,
        title: 'Internet Success',
        text: 'Internet Success website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/internetsuccess/index.htm'
    },
    {
        thumbnail: th_AFI,
        title: 'Adrenal Fatigue Institute',
        text: 'Adrenal Fatigue Institute website created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/concepts/afi/index.html'
    },
    {
        thumbnail: th_leviathandirect,
        title: 'Leviathan Direct',
        text: 'Leviathan Direct website created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/leviathan/index.html'
    },        
    {
        thumbnail: th_vividwhitesmiles,
        title: 'Vivid White Smile',
        text: 'Vivid White Smile website created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/vividwhitesmiles/index.html'
    },
    {
        thumbnail: th_melbec,
        title: 'Melbec Media',
        text: 'Melbec Media website created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'http://www.melbecmedia.com'
    },
    {
        thumbnail: th_aspire36site,
        title: 'Aspire36',
        text: 'Aspire36 website created using HTML, CSS, and Javascript. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/concepts/aspire36/aspireconcept.html'
    },
    {
        thumbnail: th_ResV,
        title: 'Resv Vanish',
        text: 'Resv Vanish website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'https://web.archive.org/web/20100327185111/http://www.resv-vanish.com/'
    },
    {
        thumbnail: th_systemsandsignals,
        title: 'Systems and Signals Cafe',
        text: 'Systems and Signals Cafe website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'https://web.archive.org/web/20090612085751/http://www.systemsandsignalscafe.com/'
    },
    {
        thumbnail: th_paloalto,
        title: 'Palo Alto Labs',
        text: 'Palo Alto Labs website created using HTML, CSS, Javascript. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/concepts/paloaltolabs/index.htm'
    },
    {
        thumbnail: th_ArcticSmile,
        title: 'Arctic Smile',
        text: 'Arctic Smile website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/articwhites/index.html'
    },
    {
        thumbnail: th_maquicoloncleanse,
        title: 'Maqui Colon Cleanser',
        text: 'Maqui Colon Cleanser website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'https://web.archive.org/web/20100301162449/http://www.maquicoloncleanser.com/'
    },
    {
        thumbnail: th_acailean,
        title: 'Acai Lean Berry',
        text: 'Acai Lean Berry website created using HTML, CSS, Javascript, and ASP.net. Prototyping done in Photoshop',
        url: 'https://web.archive.org/web/20090923143936/http://www.acaileanberry.com/'
    },
    {
        thumbnail: th_equinascorp,
        title: 'Equinas Corp',
        text: 'Equinas Corp website created using HTML, CSS, Javascript. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/sites/equinas/index.html'
    },
    {
        thumbnail: th_vasutrasite,
        title: 'Vasutra',
        text: 'Vasutra website created using HTML, CSS, Javascript. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/concepts/vasutra/index.html'
    },
    {
        thumbnail: th_Nanoprenconcept,
        title: 'Nanopren',
        text: 'Nanopren website created using HTML, CSS, Javascript. Prototyping done in Photoshop',
        url: 'http://www.draztic.com/concepts/nanopren/index.html'
    }
];

export function Websites() {

    
     return Object.entries(websiteData).map(([key, value], i) => (
            
                
                    <DrazticWebsiteDialog key={key} {...value} />

               
             
    ))

    

    
};