import React from 'react';
import { 
  makeStyles, 
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText 
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import WebIcon from '@material-ui/icons/Web';
import AppsIcon from '@material-ui/icons/Apps';
import HomeIcon from '@material-ui/icons/Home';
import LanguageIcon from '@material-ui/icons/Language';
import PrintIcon from '@material-ui/icons/Print';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import HelpIcon from '@material-ui/icons/Help';
import MessageIcon from '@material-ui/icons/Message';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: 'PerfectDark'
  },
}));



export function ButtonAppBar() {
  const classes = useStyles();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  
  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
  
    setState({ ...state, [side]: open });
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={toggleDrawer('left', true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            DraZtic
          </Typography>
      
        </Toolbar>
      </AppBar>
      <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
        <List>
         <a href="/home">
            <ListItem  button>
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary="Home" />
          </ListItem>
          </a>
          <a href="/apps">
          <ListItem button>
                <ListItemIcon><AppsIcon /></ListItemIcon>
                <ListItemText primary="App" />
          </ListItem>
          </a>
          <a href="/websites">
          <ListItem button>
                <ListItemIcon><WebIcon /></ListItemIcon>
                <ListItemText primary="Websites" />
          </ListItem>
          </a>
          <a href="/logos">
          <ListItem button>
                <ListItemIcon><LanguageIcon /></ListItemIcon>
                <ListItemText primary="Logos" />
          </ListItem>
          </a>
          <a href="/print">
          <ListItem button>
                <ListItemIcon><PrintIcon /></ListItemIcon>
                <ListItemText primary="Print Work" />
          </ListItem>
          </a>
          <a href="/animation">
          <ListItem button>
                <ListItemIcon><OndemandVideoIcon /></ListItemIcon>
                <ListItemText primary="Animation" />
          </ListItem>
          </a>
          <a href="/3d">
          <ListItem button>
                <ListItemIcon><ThreeDRotationIcon /></ListItemIcon>
                <ListItemText primary="3D Art" />
          </ListItem>
          </a>
          <a href="/about">
          <ListItem button>
                <ListItemIcon><HelpIcon /></ListItemIcon>
                <ListItemText primary="About" />
          </ListItem>
          </a>
          <a href="/contact">
          <ListItem button>
                <ListItemIcon><MessageIcon /></ListItemIcon>
                <ListItemText primary="Contact" />
          </ListItem>
          </a>
        </List>
      </Drawer>
    </div>
  );
}