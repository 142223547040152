import React from 'react';

import {DrazticDialog} from './SlideDialog.js';

import th_verifyID2019_2 from './assets/Thumbs/th_verifyID2019_2.jpg';
import verifyID2019_1 from './assets/Images3/verifyID2019/verifyID2019_1.jpg';
import verifyID2019_2 from './assets/Images3/verifyID2019/verifyID2019_2.jpg';
import verifyID2019_3 from './assets/Images3/verifyID2019/verifyID2019_3.jpg';
import verifyID2019_4 from './assets/Images3/verifyID2019/verifyID2019_4.jpg';
import verifyID2019_5 from './assets/Images3/verifyID2019/verifyID2019_5.jpg';
import verifyID2019_6 from './assets/Images3/verifyID2019/verifyID2019_6.jpg';
import verifyID2019_7 from './assets/Images3/verifyID2019/verifyID2019_7.jpg';
import verifyID2019_8 from './assets/Images3/verifyID2019/verifyID2019_8.jpg';
import verifyID2019_9 from './assets/Images3/verifyID2019/verifyID2019_9.jpg';
import verifyID2019_10 from './assets/Images3/verifyID2019/verifyID2019_10.jpg';
import verifyID2019_11 from './assets/Images3/verifyID2019/verifyID2019_11.jpg';
import verifyID2019_12 from './assets/Images3/verifyID2019/verifyID2019_12.jpg';

import th_certifyIOS from './assets/Thumbs/th_certifyIOS.jpg';
import appointments_certifyIOS from './assets/Images3/certifyIOS/appointments.jpg';
import appointmentsDate from './assets/Images3/certifyIOS/appointmentsDate.jpg';
import appointmentsList from './assets/Images3/certifyIOS/appointmentsList.jpg';
import checkinArea from './assets/Images3/certifyIOS/checkinArea.jpg';
import login_certifyIOS from './assets/Images3/certifyIOS/login.jpg';
import profile from './assets/Images3/certifyIOS/profile.jpg';
import summary_certifyIOS from './assets/Images3/certifyIOS/summary.jpg';
import verifyPatient from './assets/Images3/certifyIOS/verifyPatient.jpg';
import verifyPatientFail from './assets/Images3/certifyIOS/verifyPatientFail.jpg';
import verifyPatientSuccess from './assets/Images3/certifyIOS/verifyPatientSuccess.jpg';
import verifyProvider from './assets/Images3/certifyIOS/verifyProvider.jpg';
import verifyProviderFail from './assets/Images3/certifyIOS/verifyProviderFail.jpg';
import verifyProviderSuccess from './assets/Images3/certifyIOS/verifyProviderSuccess.jpg';
import visitStarted from './assets/Images3/certifyIOS/visitStarted.jpg';

import protoio_Certify_IOS_V5_Page_01 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_01.jpg';
import protoio_Certify_IOS_V5_Page_02 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_02.jpg';
import protoio_Certify_IOS_V5_Page_03 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_03.jpg';
import protoio_Certify_IOS_V5_Page_04 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_04.jpg';
import protoio_Certify_IOS_V5_Page_05 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_05.jpg';
import protoio_Certify_IOS_V5_Page_06 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_06.jpg';
import protoio_Certify_IOS_V5_Page_07 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_07.jpg';
import protoio_Certify_IOS_V5_Page_08 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_08.jpg';
import protoio_Certify_IOS_V5_Page_09 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_09.jpg';
import protoio_Certify_IOS_V5_Page_10 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_10.jpg';
import protoio_Certify_IOS_V5_Page_11 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_11.jpg';
import protoio_Certify_IOS_V5_Page_12 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_12.jpg';
import protoio_Certify_IOS_V5_Page_13 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_13.jpg';
import protoio_Certify_IOS_V5_Page_14 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_14.jpg';
import protoio_Certify_IOS_V5_Page_15 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_15.jpg';
import protoio_Certify_IOS_V5_Page_16 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_16.jpg';
import protoio_Certify_IOS_V5_Page_17 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_17.jpg';
import protoio_Certify_IOS_V5_Page_18 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_18.jpg';
import protoio_Certify_IOS_V5_Page_19 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_19.jpg';
import protoio_Certify_IOS_V5_Page_20 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_20.jpg';
import protoio_Certify_IOS_V5_Page_21 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_21.jpg';
import protoio_Certify_IOS_V5_Page_22 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_22.jpg';
import protoio_Certify_IOS_V5_Page_23 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_23.jpg';
import protoio_Certify_IOS_V5_Page_24 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_24.jpg';
import protoio_Certify_IOS_V5_Page_25 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_25.jpg';
import protoio_Certify_IOS_V5_Page_26 from './assets/Images3/certify_protoio/protoio_Certify_IOS_V5_Page_26.jpg';

import th_login from './assets/Thumbs/th_login.jpg'
import adminAccount from './assets/Images3/verifyAppConcept/adminAccount.jpg';
import adminAccount_securityQuestions from './assets/Images3/verifyAppConcept/adminAccount_securityQuestions.jpg';
import createRecoveryQuestions from './assets/Images3/verifyAppConcept/createRecoveryQuestions.jpg';
import crossCheckFailure from './assets/Images3/verifyAppConcept/crossCheckFailure.jpg';
import failure from './assets/Images3/verifyAppConcept/failure.jpg';
import failureAll from './assets/Images3/verifyAppConcept/failureAll.jpg';
import faq from './assets/Images3/verifyAppConcept/faq.jpg';
import firstTimeLogin from './assets/Images3/verifyAppConcept/firstTimeLogin.jpg';
import forgotPassword from './assets/Images3/verifyAppConcept/forgotPassword.jpg';
import home from './assets/Images3/verifyAppConcept/home.jpg';
import howto from './assets/Images3/verifyAppConcept/howto.jpg';
import insuranceFailure from './assets/Images3/verifyAppConcept/insuranceFailure.jpg';
import insuranceHelp from './assets/Images3/verifyAppConcept/insuranceHelp.jpg';
import insuranceID from './assets/Images3/verifyAppConcept/insuranceID.jpg';
import loader from './assets/Images3/verifyAppConcept/loader.jpg';
import login_verifyApp from './assets/Images3/verifyAppConcept/login.jpg';
import loginError from './assets/Images3/verifyAppConcept/loginError.jpg';
import loginMajorError from './assets/Images3/verifyAppConcept/loginMajorError.jpg';
import manualInsurance from './assets/Images3/verifyAppConcept/manualInsurance.jpg';
import manualStateId from './assets/Images3/verifyAppConcept/manualStateId.jpg';
import passwordReset from './assets/Images3/verifyAppConcept/passwordReset.jpg';
import photoMismatch from './assets/Images3/verifyAppConcept/photoMismatch.jpg';
import recognitionFailure from './assets/Images3/verifyAppConcept/recognitionFailure.jpg';
import recoveryQuestions from './assets/Images3/verifyAppConcept/recoveryQuestions.jpg';
import returnHome from './assets/Images3/verifyAppConcept/returnHome.png';
import selectFacility from './assets/Images3/verifyAppConcept/selectFacility.jpg';
import stateId from './assets/Images3/verifyAppConcept/stateId.jpg';
import stateIdBack from './assets/Images3/verifyAppConcept/stateIdBack.jpg';
import stateIdHelp from './assets/Images3/verifyAppConcept/stateIdHelp.jpg';
import verificationHelp from './assets/Images3/verifyAppConcept/verificationHelp.jpg';
import verified from './assets/Images3/verifyAppConcept/verified.jpg';
import verifyPatient_verifyApp from './assets/Images3/verifyAppConcept/verifyPatient.jpg';
import verifyProvider_verifyApp from './assets/Images3/verifyAppConcept/verifyProvider.jpg';

import th_NotifyMockup_Page_1 from './assets/Thumbs/th_NotifyMockup_Page_1.jpg';
import NotifyMockup_Page_1 from './assets/Images3/NotifyApp/NotifyMockup_Page_1.jpg';
import NotifyMockup_Page_2 from './assets/Images3/NotifyApp/NotifyMockup_Page_2.jpg';
import NotifyMockup_Page_3 from './assets/Images3/NotifyApp/NotifyMockup_Page_3.jpg';
import NotifyMockup_Page_4 from './assets/Images3/NotifyApp/NotifyMockup_Page_4.jpg';
import NotifyMockup_Page_5 from './assets/Images3/NotifyApp/NotifyMockup_Page_5.jpg';
import NotifyMockup_Page_6 from './assets/Images3/NotifyApp/NotifyMockup_Page_6.jpg';
import NotifyMockup_Page_7 from './assets/Images3/NotifyApp/NotifyMockup_Page_7.jpg';
import NotifyMockup_Page_8 from './assets/Images3/NotifyApp/NotifyMockup_Page_8.jpg';
import NotifyMockup_Page_9 from './assets/Images3/NotifyApp/NotifyMockup_Page_9.jpg';

import th_verify_mobile_2018 from './assets/Thumbs/th_verify_mobile_2018.jpg';
import verifymobile1 from './assets/Images5/VerifyMobile/1.jpg';
import verifymobile2 from './assets/Images5/VerifyMobile/2.jpg';
import verifymobile3 from './assets/Images5/VerifyMobile/3.jpg';
import verifymobile4 from './assets/Images5/VerifyMobile/4.jpg';
import verifymobile5 from './assets/Images5/VerifyMobile/5.jpg';
import verifymobile6 from './assets/Images5/VerifyMobile/6.jpg';
import verifymobile7 from './assets/Images5/VerifyMobile/7.jpg';
import verifymobile8 from './assets/Images5/VerifyMobile/8.jpg';
import verifymobile9 from './assets/Images5/VerifyMobile/9.jpg';
import verifymobile10 from './assets/Images5/VerifyMobile/10.jpg';
import verifymobile11 from './assets/Images5/VerifyMobile/11.jpg';
import verifymobile12 from './assets/Images5/VerifyMobile/12.jpg';
import verifymobile13 from './assets/Images5/VerifyMobile/13.jpg';
import verifymobile14 from './assets/Images5/VerifyMobile/14.jpg';
import verifymobile15 from './assets/Images5/VerifyMobile/15.jpg';
import verifymobile16 from './assets/Images5/VerifyMobile/16.jpg';
import verifymobile17 from './assets/Images5/VerifyMobile/17.jpg';
import verifymobile18 from './assets/Images5/VerifyMobile/18.jpg';

import th_biometricVerify from './assets/Thumbs/th_biometricVerify.jpg';
import biometricVerify from './assets/Images5/Verify/biometricVerify.jpg';
import biometricVerifySuccess from './assets/Images5/Verify/biometricVerifySuccess.jpg';
import insuranceDropDown from './assets/Images5/Verify/insuranceDropDown.jpg';
import insuranceInput from './assets/Images5/Verify/insuranceInput.jpg';
import insuranceInputSuccess from './assets/Images5/Verify/insuranceInputSuccess.jpg';
import insuranceScreen from './assets/Images5/Verify/insuranceScreen.jpg';
import summary from './assets/Images5/Verify/summary.jpg';

import th_2_login from './assets/Thumbs/th_2-login.jpg';
import appIcon from './assets/Images5/Certify/1-appIcon.jpg';
import facialRecognition from './assets/Images5/Certify/10-facialRecognition.jpg';
import verifiedPatient from './assets/Images5/Certify/11-verifiedPatient.jpg';
import startVisit from './assets/Images5/Certify/12-startVisit.jpg';
import visitComplete  from './assets/Images5/Certify/13-visitComplete.jpg';
import sideBar from './assets/Images5/Certify/14-sideBar.jpg';
import newVisit from './assets/Images5/Certify/15-newVisit.jpg';
import login from './assets/Images5/Certify/2-login.jpg';
import loginFilled from './assets/Images5/Certify/3-loginFilled.jpg';
import photoProvider from './assets/Images5/Certify/4-photoProvider.jpg';
import providerSuccess from './assets/Images5/Certify/5-providerSuccess.jpg';
import appointments from './assets/Images5/Certify/6-appointments.jpg';
import idScan from './assets/Images5/Certify/7-idScan.jpg';
import idScanSuccess from './assets/Images5/Certify/8-idScanSuccess.jpg';
import photoPatient from './assets/Images5/Certify/9-photoPatient.jpg';

import th_00_login from './assets/Thumbs/th_00_login.jpg';
import _00_login  from './assets/Images5/Assure/00_login.jpg';
import _01_loginDone  from './assets/Images5/Assure/01_loginDone.jpg';
import _02_deliveries  from './assets/Images5/Assure/02_deliveries.jpg';
import _03_deliveriesOpen  from './assets/Images5/Assure/03_deliveriesOpen.jpg';
import _04_idMethod  from './assets/Images5/Assure/04_idMethod.jpg';
import _05_idMethodPhoto  from './assets/Images5/Assure/05_idMethodPhoto.jpg';
import _06_idMethodBarcodeScan  from './assets/Images5/Assure/06_idMethodBarcodeScan.jpg';
import _07_idMethodBarcodeScanScanning  from './assets/Images5/Assure/07_idMethodBarcodeScanScanning.jpg';
import _08_idMethodBarcodeScanDon  from './assets/Images5/Assure/08_idMethodBarcodeScanDone.jpg';
import _09_idMethodIdSwipe  from './assets/Images5/Assure/09_idMethodIdSwipe.jpg';
import _10_idMethodPhotoDone  from './assets/Images5/Assure/10_idMethodPhotoDone.jpg';
import _11_summary  from './assets/Images5/Assure/11_summary.jpg';
import _12_sidebar  from './assets/Images5/Assure/12_sidebar.jpg';

  const mobileAppsData = [
    {
        thumbnail: th_verifyID2019_2,
        title: 'Verify Mobile 2019',
        slideImages: [
            verifyID2019_1,
            verifyID2019_2,
            verifyID2019_3,
            verifyID2019_4,
            verifyID2019_5,
            verifyID2019_6,
            verifyID2019_7,
            verifyID2019_8,
            verifyID2019_9,
            verifyID2019_10,
            verifyID2019_11,
            verifyID2019_12,
        ],
        text: "Verify Mobile 2019 Native Android and IOS App.  Uses Camera Biometrics to scan a user's face as well as their Identification Cards to determine the identity of the user. Used in Opioid clinics."
    },
    {
            thumbnail: th_certifyIOS,
            title: 'Certify IOS',
            slideImages: [
                    appointments_certifyIOS, 
                    appointmentsDate, 
                    appointmentsList,  
                    checkinArea, 
                    login_certifyIOS, 
                    profile,
                    summary_certifyIOS, 
                    verifyPatient,
                    verifyPatientFail,
                    verifyPatientSuccess,
                    verifyProvider,
                    verifyProviderFail,
                    verifyProviderSuccess,
                    visitStarted,
            ],
            text: "Certify Native IOS App.  Uses Camera Biometrics to scan a user's face as well as their Identification Cards to determine the identity of the user. Used in Home Health Care facilities."
     },
     {
                thumbnail: th_login,
                title: 'Certify IOS Version 2',
                slideImages: [
                    protoio_Certify_IOS_V5_Page_01,
                    protoio_Certify_IOS_V5_Page_02,
                    protoio_Certify_IOS_V5_Page_03,
                    protoio_Certify_IOS_V5_Page_04,
                    protoio_Certify_IOS_V5_Page_05,
                    protoio_Certify_IOS_V5_Page_06,
                    protoio_Certify_IOS_V5_Page_07,
                    protoio_Certify_IOS_V5_Page_08,
                    protoio_Certify_IOS_V5_Page_09,
                    protoio_Certify_IOS_V5_Page_10,
                    protoio_Certify_IOS_V5_Page_11,
                    protoio_Certify_IOS_V5_Page_12,
                    protoio_Certify_IOS_V5_Page_13,
                    protoio_Certify_IOS_V5_Page_14,
                    protoio_Certify_IOS_V5_Page_15,
                    protoio_Certify_IOS_V5_Page_16,
                    protoio_Certify_IOS_V5_Page_17,
                    protoio_Certify_IOS_V5_Page_18,
                    protoio_Certify_IOS_V5_Page_19,
                    protoio_Certify_IOS_V5_Page_20,
                    protoio_Certify_IOS_V5_Page_21,
                    protoio_Certify_IOS_V5_Page_22,
                    protoio_Certify_IOS_V5_Page_23,
                    protoio_Certify_IOS_V5_Page_24,
                    protoio_Certify_IOS_V5_Page_25,
                    protoio_Certify_IOS_V5_Page_26,
                ],
                text: "Certify IOS Version 2 Native IOS App.  Uses Camera Biometrics to scan a user's face as well as their Identification Cards to determine the identity of the user. Used in Home Health Care facilities."
     },
     {
                    thumbnail: th_NotifyMockup_Page_1,
                    title: 'Notify App',
                    slideImages: [
                        NotifyMockup_Page_1,
                        NotifyMockup_Page_2,
                        NotifyMockup_Page_3,
                        NotifyMockup_Page_4,
                        NotifyMockup_Page_5,
                        NotifyMockup_Page_6,
                        NotifyMockup_Page_7,
                        NotifyMockup_Page_8,
                        NotifyMockup_Page_9,
                    ],
                    text: "Notify Desktop App.  Gathers data from Verify and Certify Desktop and Mobile Apps for Reports."
      },
    {
                        thumbnail: th_login,
                        title: 'Verify Mobile First Version',
                        slideImages: [
                            adminAccount,
                            adminAccount_securityQuestions,
                            createRecoveryQuestions,
                            crossCheckFailure,
                            failure,
                            failureAll,
                            faq, 
                            firstTimeLogin,
                            forgotPassword,
                            home,
                            howto,
                            insuranceFailure,
                            insuranceHelp,
                            insuranceID,
                            loader,
                            login_verifyApp,
                            loginError,
                            loginMajorError,
                            manualInsurance,
                            manualStateId,
                            passwordReset,
                            photoMismatch,
                            recognitionFailure,
                            recoveryQuestions,
                            returnHome,
                            selectFacility,
                            stateId,
                            stateIdBack,
                            stateIdHelp,
                            verificationHelp,
                            verified,
                            verifyPatient_verifyApp,
                            verifyProvider_verifyApp,
                        ],
      text: "Verify Mobile Native Android and IOS App.  Uses Camera Biometrics to scan a user's face as well as their Identification Cards to determine the identity of the user. Used in Opioid clinics."
    },
    {
    thumbnail: th_verify_mobile_2018,
    title: 'Verify Mobile 2018',
    slideImages: [
        verifymobile1,
        verifymobile2,
        verifymobile3,
        verifymobile4,
        verifymobile5,
        verifymobile6,
        verifymobile7,
        verifymobile8,
        verifymobile9,
        verifymobile10,
        verifymobile11,
        verifymobile12,
        verifymobile13,
        verifymobile14,
        verifymobile15,
        verifymobile16,
        verifymobile17,
        verifymobile18,
    ],
    text: "Verify Mobile Native Android and IOS App.  Uses Camera Biometrics to scan a user's face as well as their Identification Cards to determine the identity of the user. Used in Opioid clinics."
    },
    {
        thumbnail: th_biometricVerify,
        title: 'Verify Desktop',
        slideImages: [
            biometricVerify,
            biometricVerifySuccess,
            insuranceDropDown,
            insuranceInput,
            insuranceInputSuccess,
            insuranceScreen,
            summary
        ],
        text: "Verify Desktop App for Windows built using Angular and Electron.  Uses a USB Palm Vein Scanner to Verify a User's handprint as well as a Barcode Swiper for the user's Identification Card in Hospitals."
    },
    {
        thumbnail: th_2_login,
        title: 'Certify',
        slideImages: [
            appIcon,
            facialRecognition,
            verifiedPatient,
            startVisit,
            visitComplete,
            sideBar,
            newVisit,
            login,
            loginFilled,
            photoProvider,
            providerSuccess,
            appointments,
            idScan,
            idScanSuccess,
            photoPatient
        ],
        text: "Certify Mobile Application using Cordova Hybrid Framework for IOS and Android.  Uses Camera Biometrics to scan a user's face as well as their Identification Cards to determine the identity of the user. Used in Home Health Care facilities"
    },
    {
        thumbnail: th_00_login,
        title: 'Assure',
        slideImages: [
            _00_login,
            _01_loginDone,
            _02_deliveries,
            _03_deliveriesOpen,
            _04_idMethod,
            _05_idMethodPhoto,
            _06_idMethodBarcodeScan,
            _07_idMethodBarcodeScanScanning,
            _08_idMethodBarcodeScanDon,
            _09_idMethodIdSwipe,
            _10_idMethodPhotoDone,
            _11_summary,
            _12_sidebar
        ],
        text: "Assure Mobile Application using Cordova Hybrid Framework for IOS and Android.  Uses Camera Biometrics to scan a user's face as well as their Identification Cards to determine the identity of the user. Used to deliver DME equipment."
    }
];
  

export function MobileApps() {



    
        return Object.entries(mobileAppsData).map(([key, value], i) => (
            
                
                    <DrazticDialog key={key} {...value} />

           
             
         ))

    


    
};