import React from 'react';

import {DrazticDialog} from './SlideDialog.js';

import th_f4AV from './assets/Images4/f4AV.jpg';
import f4phantom from './assets/Images4/f4phantom.jpg';
import th_dance3 from './assets/Thumbs/th_dance3.3.jpg';
import dance1 from './assets/Images4/dance1.16.jpg';
import dance3 from './assets/Images4/dance3.3.jpg';
import inside1 from './assets/Images4/inside1.16.jpg';
import inside2 from './assets/Images4/inside2.20.jpg';
import pose from './assets/Images4/pose.1.jpg';
import run2 from './assets/Images4/run2.3.jpg';
import th_column2 from './assets/Thumbs/th_column2.jpg';
import column from './assets/Images4/column.jpg';
import column2 from './assets/Images4/column2.jpg';
import column3 from './assets/Images4/column3.jpg';
import column4 from './assets/Images4/column4.jpg';
import column5 from './assets/Images4/column5.jpg';
import th_pebbles from './assets/Thumbs/th_pebbles.jpg';
import pebbles from './assets/Images4/pebbles.jpg';
import pebbles2 from './assets/Images4/pebbles2.jpg';
import th_penguinrender from './assets/Thumbs/th_penguinrender.jpg';
import penguinrender from './assets/Images4/penguinrender.jpg';
import robotrender from './assets/Images4/robotrender.jpg';
import th_fishgrab from './assets/Thumbs/th_fishgrab.100.jpg';
import penguinfinger from './assets/Images5/penguinfinger.40.jpg';
import robotpoint from './assets/Images5/robotpoint.100.jpg';
import fishgrab from './assets/Images5/fishgrab.100.jpg';
import uhoh from './assets/Images5/uhoh.60.jpg';
import gunpoint2 from './assets/Images5/gunpoint2.30.jpg';
import th_blade1 from './assets/Thumbs/th_blade1.jpg';
import modelsheet from './assets/Images5/modelsheet.jpg';
import th_TrainingDojo from './assets/Thumbs/th_TrainingDojo.jpg';
import TrainingDojo from './assets/Images5/TrainingDojo.jpg';
import th_DemonLegend from './assets/Thumbs/th_DemonLegend.jpg';
import DemonLegend from './assets/Images5/DemonLegend.jpg';
import th_HeroLegend from './assets/Thumbs/th_HeroLegend.jpg';
import HeroLegend from './assets/Images5/HeroLegend.jpg';
import th_DaguRenderSheet from './assets/Thumbs/th_DaguRenderSheet.jpg';
import DaguRenderSheet from './assets/Images2/DaguRenderSheet.jpg';
import th_KydraRenderSheet from './assets/Thumbs/th_KydraRenderSheet.jpg';
import KydraRenderSheet from './assets/Images2/KydraRenderSheet.jpg';
import th_Woman1 from './assets/Thumbs/th_Woman1.jpg';
import Woman1 from './assets/Images/Woman1.jpg';
import Bald1 from './assets/Images/Bald1.jpg';
import Bald2 from './assets/Images/Bald2.jpg';
import Bald3 from './assets/Images/Bald3.jpg';
import Bald4 from './assets/Images/Bald4.jpg';
import Bald5 from './assets/Images/Bald5.jpg';
import Bald6 from './assets/Images/Bald6.jpg';
import HairRender1 from './assets/Images/HairRender1.jpg';
import HairRender2 from './assets/Images/HairRender2.jpg';
import Woman2 from './assets/Images/Woman2.jpg';
import Woman3 from './assets/Images/Woman3.jpg';
import Woman4 from './assets/Images/Woman4.jpg';
import Woman5 from './assets/Images/Woman5.jpg';
import Woman6 from './assets/Images/Woman6.jpg';
import Woman7 from './assets/Images/Woman7.jpg';
import Woman8 from './assets/Images/Woman8.jpg';
import Woman9 from './assets/Images/Woman9.jpg';
import th_Horse1 from './assets/Thumbs/th_Horse1.jpg';
import Horse1 from './assets/Images2/Horse1.jpg';
import Horse2 from './assets/Images2/Horse2.jpg';
import Horse3 from './assets/Images2/Horse3.jpg';
import Horse4 from './assets/Images2/Horse4.jpg';
import Horse5 from './assets/Images2/Horse5.jpg';
import Horse6 from './assets/Images2/Horse6.jpg';
import Horse7 from './assets/Images2/Horse7.jpg';
import Horse8 from './assets/Images2/Horse8.jpg';
import Horse9 from './assets/Images2/Horse9.jpg';
import Horse10 from './assets/Images2/Horse10.jpg';
import th_Windmill1 from './assets/Thumbs/th_Windmill1.jpg';
import Windmill1 from './assets/Images/Windmill1.jpg';
import Windmill2 from './assets/Images/Windmill2.jpg';
import Windmill3 from './assets/Images/Windmill3.jpg';
import Windmill4 from './assets/Images/Windmill4.jpg';
import Windmill5 from './assets/Images/Windmill5.jpg';
import Windmill6 from './assets/Images/Windmill6.jpg';
import Windmill7 from './assets/Images/Windmill7.jpg';
import Windmill8 from './assets/Images/Windmill8.jpg';
import th_stump1 from './assets/Thumbs/th_stump1.jpg';
import stump1 from './assets/Images/stump1.jpg';
import stump2 from './assets/Images/stump2.jpg';
import stump3 from './assets/Images/stump3.jpg';
import stump4 from './assets/Images/stump4.jpg';
import stump5 from './assets/Images/stump5.jpg';
import stump6 from './assets/Images/stump6.jpg';
import stump7 from './assets/Images/stump7.jpg';
import stump8 from './assets/Images/stump8.jpg';
import th_cmodel from './assets/Thumbs/th_cmodel.jpg';
import cmodel1 from './assets/Images/cmodel1.jpg';
import cmodel2 from './assets/Images/cmodel2.jpg';
import cmodel3 from './assets/Images/cmodel3.jpg';
import cmodel4 from './assets/Images/cmodel4.jpg';
import cmodel5 from './assets/Images/cmodel5.jpg';
import cmodel6 from './assets/Images/cmodel6.jpg';
import cmodel7 from './assets/Images/cmodel7.jpg';
import cmodel8 from './assets/Images/cmodel8.jpg';
              
      
const computerArtData = [
    {
        thumbnail: th_f4AV,
        slideImages: [
            f4phantom
        ],
        title: "F4 Phantom", 
        text: "F4 Phantom by DraZtic created using Maya 3D Software"
        },
              {
                thumbnail: th_dance3,
                slideImages: [
                    dance1,
                    dance3,
                    inside1,
                    inside2,
                    pose,
                    run2
                ],  
                title: "Final Stills", 
                text: "Demon Inside Final Stills by DraZtic created using Maya 3D Software"
            },   
              {
                thumbnail: th_column2,
                slideImages: [
                    column,
                    column2,
                    column3,
                    column4,
                    column5
                ],  
                title: "Matrix", 
                text: "3D Matrix by DraZtic created using Maya 3D Software"
                  },
              {
                thumbnail: th_pebbles,
                slideImages: [
                    pebbles,
                    pebbles2
                ],  
                title: "Pebbles",
                 text: "3D Pebbles by DraZtic created using Maya 3D Software"
                  },
                  
              {
                thumbnail: th_penguinrender,
                slideImages: [
                    penguinrender,
                    robotrender
                ],  
                title: "Penguin Render", 
                text: "Penguin Render by DraZtic created using Maya 3D Software"
                  },
              {
                thumbnail: th_fishgrab,
                slideImages: [
                    penguinfinger,
                    robotpoint,
                    fishgrab,
                    uhoh,
                    gunpoint2
                ],  
                text: "Penguin 3D", 
                title: "Penguin 3D Render by DraZtic created using Maya 3D Software"
    
               },

              {
                thumbnail: th_blade1,
                slideImages: [
                    modelsheet
                ],  
                title: "Model Sheet 3D", 
                text: "Model Sheet 3D by DraZtic created using 3D Studio Max Software"
                  },
              {
                thumbnail: th_TrainingDojo,
                slideImages: [
                    TrainingDojo
                ],  
                title: "Training Dojo", 
                text: "Training Dojo by DraZtic created using 3D Studio Max Software"
                  },
              {
                thumbnail: th_DemonLegend,
                slideImages: [
                    DemonLegend
                ],  
                title: "Demon Legend", 
                text: "Demon Legend by DraZtic created using Maya 3D Software"
                  },
              {
                thumbnail: th_HeroLegend,
                slideImages: [
                    HeroLegend
                ],  
                title: "Hero Legend", 
                text: "Hero Legend by DraZtic created using 3D Studio Max Software"
                  },
              {
                thumbnail: th_DaguRenderSheet,
                slideImages: [
                    DaguRenderSheet
                ],  
                title: "Dagu Render Sheet", 
                text: "Dagu Render Sheet by DraZtic created using Maya 3D Software"
                  },
              {
                thumbnail: th_KydraRenderSheet,
                slideImages: [
                    KydraRenderSheet
                ],  
                title: "Kydra Render Sheet", 
                text: "Kydra Render Sheet by DraZtic created using Maya 3D Software"
                  },

              {
                thumbnail: th_Woman1,
                slideImages: [
                        Woman1,
                        Bald1,
                            Bald2,
                            Bald3,
                            Bald4,
                            Bald5,
                            Bald6,
                            HairRender1,
                            HairRender2,
                            Woman2,
                            Woman3,
                            Woman4,
                            Woman5,
                            Woman6,
                            Woman7,
                            Woman8,
                            Woman9
                ],  
                title: "Woman Render",
                text: "Woman Render by DraZtic created using Maya 3D Software"
                  },
                  
              {
                thumbnail: th_Horse1,
                slideImages: [
                    Horse1,
                    Horse2,
                        Horse3,
                        Horse4,
                        Horse5,
                        Horse6,
                        Horse7,
                        Horse8,
                        Horse9,
                        Horse10
                ],  
                title: "3D Game Horse", 
                text: "3D Horse for PC Pet Simulation Game by DraZtic created using 3D Studio Max Software"
                  },
                  
              {
                thumbnail: th_Windmill1,
                slideImages: [
                    Windmill1,
                    Windmill2,
                        Windmill3,
                        Windmill4,
                        Windmill5,
                        Windmill6,
                        Windmill7,
                        Windmill8
                ],  
                title: "Windmill Horse", 
                text: "Windmill Horse for PC Pet Simulation Game by DraZtic created using 3D Studio Max Software"
                  },
                  
              {
                thumbnail: th_stump1,
                slideImages: [
                    stump1,
                    stump2,
                        stump3,
                        stump4,
                        stump5,
                        stump6,
                        stump7,
                        stump8
                ],  
                title: "3D Stump", 
                text: "3D Stump by DraZtic created using Maya 3D Software"
             },
                  
              {
                thumbnail: th_cmodel,
                slideImages: [
                    cmodel1,
                    cmodel2,
                        cmodel3,
                        cmodel4,
                        cmodel5,
                        cmodel6,
                        cmodel7,
                        cmodel8 
                ],  
                title: "C Model", 
                text: "C Model by DraZtic created using Maya 3D Software"
                
            }     
];  


export function ComputerArt() {



    
    return Object.entries(computerArtData).map(([key, value], i) => (
        
            
                <DrazticDialog key={key} {...value} />

       
         
     ))





};