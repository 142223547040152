import React from 'react';

import {DrazticFlashDialog} from './FlashDialog.js';

import Dermalift2 from './assets/sites/dermaliftplatinum/flash/Dermalift2.swf';
import RejuvinX_1 from './assets/Animations2/RejuvinX_1.swf';
import rejuvinxbanner from './assets/Animations2/rejuvinxbanner.swf';
import paravol from './assets/concepts/paloaltolabs/paravol.swf';
import incarnate from './assets/concepts/paloaltolabs/incarnate.swf';
import aspire36 from './assets/concepts/paloaltolabs/aspire36.swf';
import aspirelite from './assets/concepts/paloaltolabs/aspirelite.swf';
import reset_ad from './assets/concepts/paloaltolabs/reset-ad.swf';
import leviathan from './assets/concepts/paloaltolabs/leviathan.swf';
import Dermalift from './assets/sites/dermaliftoriginal/flash/Dermalift.swf';
import _400x500 from './assets/Animations2/400x500.swf';
import flashbox from './assets/Animations2/flashbox.swf';
import th_dermalift2 from './assets/Thumbs/th_dermalift2.jpg';
import th_Rejuvinx from './assets/Thumbs/th_Rejuvinx.jpg';
import th_paloalto from './assets/Thumbs/th_paloalto.jpg';
import th_dermalift from './assets/Thumbs/th_dermalift.jpg';
import th_maxcolo from './assets/Thumbs/th_maxcolo.jpg';
import th_celebritywhite from './assets/Thumbs/th_celebritywhite.jpg';
import th_afilogo from './assets/Thumbs/th_afilogo.gif';
import mainbox from './assets/concepts/afi/images/mainbox.swf';
import th_vectralogo2 from './assets/Thumbs/th_vectralogo2.gif';
import VectraCapitalCorp from './assets/sites/vectracapital/images/VectraCapitalCorp.swf';
import th_leviathandirect from './assets/Thumbs/th_leviathandirect.jpg';
import topbar_Reloaded from './assets/sites/leviathan/reloadedimages/topbar_Reloaded.swf';
import th_ShortSale from './assets/Thumbs/th_ShortSale.jpg';
import shortsaleflash from './assets/Animations2/shortsaleflash.swf';
import th_SquishyTarzan from './assets/Thumbs/th_SquishyTarzan.jpg';
import videopart2 from './assets/Animations2/videopart2.swf';
import th_DemoReel from './assets/Thumbs/th_DemoReel.jpg';
import DemoReel from './assets/Animations/DemoReel.swf';
import th_TEMP16 from './assets/Thumbs/th_TEMP16.jpg';
import Movie2 from './assets/Animations2/Movie2.swf';
import th_demoninside from './assets/Thumbs/th_demoninside.jpg';
import DemonInside from './assets/DemonInside/DemonInside.swf';
import th_Flatbook from './assets/Thumbs/th_Flatbook.jpg';
import Movie1 from './assets/Animations/Movie1.swf';
import th_pebbles from './assets/Thumbs/th_pebbles.jpg';
import pebbles from './assets/Animations2/pebbles.swf';
import stoneriser from './assets/Animations2/stoneriser.swf';
import th_EvilEggThumb from './assets/Thumbs/th_EvilEggThumb.jpg';
import EvilEggWalk from './assets/Animations2/EvilEggWalk.swf';
import th_GamelevelThumb from './assets/Thumbs/th_GamelevelThumb.jpg';
import GameLevel from './assets/Animations2/GameLevel.swf';
import th_Display14 from './assets/Thumbs/th_Display14.jpg';
import StonesSword from './assets/Animations2/StonesSword.swf';
import th_dance1 from './assets/Thumbs/th_dance1.16.jpg';
import FinalProject from './assets/Animations2/FinalProject.swf';
import th_kurohogsaga from './assets/Thumbs/th_kurohogsaga.jpg';
import kurohogMovie from './assets/Kurohog/kurohogMovie.swf'
import SquishyTarzan from './assets/Animations2/SquishyTarzan.swf';
import th_JackAV from './assets/Images4/JackAV.jpg';
import Jack from './assets/Animations2/Jack.swf';
import th_kirby16 from './assets/Thumbs/th_kirby16.jpg';
import Kirby from './assets/Animations2/Kirby.swf';
import th_BoxingImage from './assets/Thumbs/th_BoxingImage.jpg';
import BoxingDemo from './assets/Animations/BoxingDemo.swf';
import th_ThunderImage from './assets/Thumbs/th_ThunderImage.jpg';
import ParticleDemo from './assets/Animations/ParticleDemo.swf';
import th_NinjaWarriorImage from './assets/Thumbs/th_NinjaWarriorImage.jpg';
import InstancingDemo from './assets/Animations/InstancingDemo.swf';
import th_DynamicsTitle from './assets/Thumbs/th_DynamicsTitle.jpg';
import FinalDemo from './assets/Animations/FinalDemo.swf';
import th_DaguRenderRedux from './assets/Thumbs/th_DaguRenderRedux.jpg';
import DraZticIntroReduxa from './assets/Animations/DraZticIntroReduxa.swf';
import th_DraZticFlashIntro from './assets/Thumbs/th_DraZticFlashIntro.jpg';
import DraZticIntroRedux2 from './assets/Animations/DraZticIntroRedux2.swf';
import th_robotpoint from './assets/Thumbs/th_robotpoint.100.jpg';
import restricted from './assets/Animations2/restricted.swf';
import th_Penguin from './assets/Images4/Penguin.jpg';
import Penguinx from './assets/Animations2/Penguinx.swf';

      
  const animationsData = [
    {
            thumbnail: th_dermalift2,
            swf: Dermalift2, 
            title:"Dermalift Platinum",
            text:"Dermalift Platinum, animated by DraZtic using Adobe Photoshop and Flash"
        },
    {
            thumbnail: th_Rejuvinx,
            swf: RejuvinX_1, 
            title:"Rejuvinx", 
            text:"Rejuvinx, animated by DraZtic using Adobe Photoshop and Flash"
        },
        {
            thumbnail: th_Rejuvinx,
            swf: rejuvinxbanner,
            title:"Rejuvinx", 
            text:"Rejuvinx, animated by DraZtic using Adobe Photoshop and Flash" 
        },
        {
            thumbnail: th_paloalto,
            swf: paravol,
            title: "Paravol", 
            text:"Palo Alto Labs Paravol, animated by DraZtic using Adobe Photoshop and Flash"
        },
        {
            thumbnail: th_paloalto,
            swf: incarnate,
            title: "Incarnate", 
            text:"Palo Alto Labs Incarnate, animated by DraZtic using Adobe Photoshop and Flash"
        },
        {
            thumbnail: th_paloalto,
            swf: aspire36,
            title: "Aspire36", 
            text:"Palo Alto Labs Aspire36, animated by DraZtic using Adobe Photoshop and Flash"
        },
        {
            thumbnail: th_paloalto,
            swf: aspirelite,
            title: "Aspire Lite", 
            text:"Palo Alto Labs Aspire Lite, animated by DraZtic using Adobe Photoshop and Flash"
        },
        {
            thumbnail: th_paloalto,
            swf: reset_ad,
            title: "Reset AD", 
            text:"Palo Alto Labs Reset AD, animated by DraZtic using Adobe Photoshop and Flash"
        },
        {
            thumbnail: th_paloalto,
            swf: leviathan,
            title: "Leviathan", 
            text:"Palo Alto Labs Leviathan, animated by DraZtic using Adobe Photoshop and Flash"
        },
    {
            thumbnail: th_dermalift,
            swf: Dermalift,
             title:"Dermalift", 
             text:"Dermalift, animated by DraZtic using Adobe Photoshop and Flash"
        },
    {
        thumbnail: th_maxcolo,
        swf: _400x500,
        title: 'Maximum Cleanse',
        text:"Maximum Cleanse, animated by DraZtic using Adobe Photoshop and Flash"
        },
    {
        thumbnail: th_celebritywhite,
        swf: flashbox, 
        title:"Celebrity White", 
        text:"Celebrity White, animated by DraZtic using Adobe Photoshop and Flash"
        },
    {
        thumbnail: th_afilogo,
        swf: mainbox,
        title:"Adrenal Fatigue Institute",
        text:"AFI, animated by DraZtic using Adobe Photoshop and Flash"
        },
    {
         thumbnail: th_vectralogo2,
         swf: VectraCapitalCorp,
         title:"Vectra Capital Corp", 
         text:"Vectra Capital, animated by DraZtic using Adobe Photoshop and Flash"
        },
    {
        thumbnail: th_leviathandirect,
        swf: topbar_Reloaded, 
        title:"Leviathan Reloaded",
        text:"Leviathan Reloaded, animated by DraZtic using Adobe Photoshop and Flash"
        },

    {
        thumbnail: th_ShortSale,
        swf: shortsaleflash, 
        title:"Short Sale Dollars", 
        text:"Short Sale Dollars, animated by DraZtic using Adobe Photoshop and Flash"
        },
    {
        thumbnail: th_SquishyTarzan,
        swf: videopart2, 
        title:"Squishy Tarzan",
        text:"Squishy Tarzan, animated by DraZtic using pencil, pen, and video"
        },
    {
        thumbnail: th_DemoReel,
        swf: DemoReel, 
        title:"DraZtic Art Demo Reel",
        text:"DraZtic Demo Reel containing multiple animations"
        },
    {
        thumbnail: th_TEMP16,
        swf: Movie2, 
        title:"Super Ball", 
        text:"Super Ball, animated by DraZtic using pencil, pen, and video"
        },
    {
        thumbnail: th_demoninside,
        swf: DemonInside, 
        title:"Demon Inside",
        text:"Demon Inside, animated by DraZtic using Maya 3D software"
        },
    {
        thumbnail: th_Flatbook,
        swf: Movie1,
        title:"DraZtic Art Flat Book",
        text:"DraZtic Art Flat Book containing multiple art pieces"
        },

    {
        thumbnail: th_pebbles,
        swf: pebbles, 
        title:"Quick Time Pebbles Demo",
        text:"Pebbles Demo by DraZtic created using Maya 3D software command prompts"
        },
        {
        thumbnail: th_pebbles,
        swf: stoneriser, 
        title:"Quick Time Rising Stones",
        text:"Stones Demo by DraZtic created using Maya 3D software command prompts"
        },
    {
         thumbnail: th_EvilEggThumb,
         swf: EvilEggWalk,
         title:"Evil Egg Walk",
          text:"Evil Egg Walk animated by DraZtic using pencil, pen, and video"
        },
    {
        thumbnail: th_GamelevelThumb,
        swf: GameLevel,
        title:"Game Level", 
        text:"Game Level designed by DraZtic using 3D Studio Max 3D Software"
        },
    {
        thumbnail: th_Display14,
        swf: StonesSword,
        title:"Stone's Sword",
        text:"Sword Stone by DraZtic animated using pencil, pen, and video"
        },
    {
        thumbnail: th_dance1,
        swf: FinalProject,
        title:"Final Project",
        text:"Final Project by DraZtic animated using Maya 3D Software"
        },
    {
        thumbnail: th_kurohogsaga,
        swf: kurohogMovie, 
        title:"Kurohog Saga",
        text:"Kurohog Saga by DraZtic animated using Maya 3D Software"
        },

    {
        thumbnail: th_SquishyTarzan,
        swf: SquishyTarzan, 
        title:"Squishy Tarzan",
        text:"Squishy Tarzan by DraZtic animated using pencil, pen, and video"
        },
    {
        thumbnail: th_JackAV,
        swf: Jack, 
        title:"Jack in The Box",
        text:"Jack In The Box by DraZtic animated using Maya 3D Software"
        },
    {
        thumbnail: th_kirby16,
        swf: Kirby, 
        title:"Kirby Ball",
        text: "Kirby Bally by DraZtic animated using Maya 3D Software"
        },
    {
        thumbnail: th_BoxingImage,
        swf: BoxingDemo, 
        title:"Boxing Demo",
        text:"Boxing Demo by DraZtic animated using Maya 3D Software"
        },
    {
        thumbnail: th_ThunderImage,
        swf: ParticleDemo, 
        title:"Particle Demo",
        text:"Particle Demo by DraZtic animated using Maya 3D Software"
        },
    {
        thumbnail: th_NinjaWarriorImage,
        swf: InstancingDemo, 
        title:"Ninja Warrior", 
        text:"Ninja Warrior by DraZtic animated using Maya 3D Software"
        },

    {
        thumbnail: th_DynamicsTitle,
        swf: FinalDemo, 
        title:"Final Demo", 
        text:"Final Demo by DraZtic animated using Maya 3D Software"
        },
    {
        thumbnail: th_DaguRenderRedux,
        swf: DraZticIntroReduxa, 
        title:"Dagu Intro",
        text:"Dagu Intro by DraZtic animated using Maya 3D Software"
        },
    {
        thumbnail: th_DraZticFlashIntro,
        swf: DraZticIntroRedux2, 
        title:"DraZtic Flash Intro",
        text:"DraZtic Flash Intro animated using Adobe Flash Software"
        },
    {
        thumbnail: th_robotpoint,
        swf: restricted, 
        title:"Restricted Area",
        text:"Restricted Area Demo by DraZtic animated using Maya 3D Software"
        },
    {
        thumbnail: th_Penguin,
        swf: Penguinx, 
        title:"Penguin Demo",
        text:"Penguin Demo by DraZtic animated using pencil and video"
        }, 
  ];




export function AnimationsFlash() {



    
        return Object.entries(animationsData).map(([key, value], i) => (
            
                
                    <DrazticFlashDialog key={key} {...value} />

           
             
         ))

    


    
};