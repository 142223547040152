import React from 'react';

import {DrazticDialog} from './SlideDialog.js';

import th_MedicFP_CD_Case_01 from './assets/Thumbs/th_MedicFP_CD_Case-01.jpg';
import MedicFP_9x12x4_2P_Flattened from './assets/Images3/MedicFPCDCases/MedicFP_9x12x4_2P_Flattened.jpg';
import MedicFP_CD_Case_Flattened_Page_1 from './assets/Images3/MedicFPCDCases/MedicFP_CD_Case_Flattened_Page_1.jpg';
import MedicFP_CD_Case_Flattened_Page_2 from './assets/Images3/MedicFPCDCases/MedicFP_CD_Case_Flattened_Page_2.jpg';
import MedicFP_CD_Case_01 from './assets/Images3/MedicFPCDCases/MedicFP_CD_Case-01.png';
import MedicFP_CD_Label from './assets/Images3/MedicFPCDCases/MedicFP_CD_Label.jpg';
import th_MedicFP_Documentation_Template from './assets/Thumbs/th_MedicFP-Documentation-Template.jpg';
import MedicFP_Documentation_Template from './assets/Images3/medicfptemplate/MedicFP_Documentation_Template.jpg';
import MedicFP_Documentation_Template2 from './assets/Images3/medicfptemplate/MedicFP_Documentation_Template2.jpg';
import MedicFP_Documentation_Template3 from './assets/Images3/medicfptemplate/MedicFP_Documentation_Template3.jpg';
import MedicFP_Documentation_Template4 from './assets/Images3/medicfptemplate/MedicFP_Documentation_Template4.jpg';
import MedicFP_Documentation_Template5 from './assets/Images3/medicfptemplate/MedicFP_Documentation_Template5.jpg';
import th_Leviathan_ad_v2 from './assets/Thumbs/th_Leviathan_ad_v2.jpg';
import Leviathan_ad_v2 from './assets/Images/Leviathan_ad_v2.jpg';
import th_LeviathanReloaded from './assets/Thumbs/th_LeviathanReloaded.jpg';
import LeviathanReloadedMU from './assets/Images2/LeviathanReloadedMU.jpg';
import Leviathan_Label_Reloaded from './assets/Images2/Leviathan_Label_Reloaded.jpg';
import th_cylaprilbox from './assets/Thumbs/th_cylaprilbox.jpg';
import Cylapriil_Box from './assets/Images2/Cylapriil_Box.jpg';
import Cylapril_Bottle from './assets/Images2/Cylapril_Bottle.jpg';
import th_Paravol_Ad_M from './assets/Thumbs/th_Paravol_Ad_M.jpg';
import Paravol_Ad_MF from './assets/Images4/Paravol_Ad_MF.jpg';
import th_FitnessFirstCatalog from './assets/Thumbs/th_FitnessFirstCatalog.jpg';
import FitnessFirstCatalog from './assets/Images4/FitnessFirstCatalog.jpg';
import th_aspirebrochure from './assets/Thumbs/th_aspirebrochure.jpg';
import _11x17_Outside from './assets/Images4/11x17_Outside.jpg';
import th_leviathanad from './assets/Thumbs/th_leviathanad.jpg';
import LeviathanAd from './assets/Images2/LeviathanAd.jpg';
import th_Leviathan_Ad_3p from './assets/Thumbs/th_Leviathan_Ad_3p.jpg';
import Leviathan_Ad_3p from './assets/Images/Leviathan_Ad_3p.jpg';
import th_Paravol_Ad_3p from './assets/Thumbs/th_Paravol_Ad_3p.jpg';
import Paravol_Ad_3p from './assets/Images/Paravol_Ad_3p.jpg';
import th_LeviathanB from './assets/Thumbs/th_LeviathanB.jpg';
import LeviathanBO from './assets/Images/LeviathanBO.jpg';
import LeviathanBI from './assets/Images/LeviathanBI.jpg';
import th_LeviathanLabel from './assets/Thumbs/th_LeviathanLabel.jpg';
import LeviathanLabel from './assets/Images/LeviathanLabel.jpg';
import leviathanbottle from './assets/Images/leviathanbottle.jpg';
import th_Incarnate_bottle from './assets/Thumbs/th_Incarnate_bottle.jpg';
import Incarnate_bottle from './assets/Images/Incarnate_bottle.jpg';
import IncarnateLabel from './assets/Images/IncarnateLabel.jpg';
import th_Incarnate_ad from './assets/Thumbs/th_Incarnate_ad.jpg';
import Incarnate_ad from './assets/Images/Incarnate_ad.jpg';
import th_ResetBottle from './assets/Thumbs/th_ResetBottle.jpg';
import Reset_Bottle from './assets/Images/Reset-Bottle.jpg';
import Reset_AD from './assets/Images/Reset-AD.jpg';
import th_resetbrochure from './assets/Thumbs/th_resetbrochure.jpg';
import Reset_AD_BI from './assets/Images2/Reset_AD_BI.jpg';
import Reset_AD_BO from './assets/Images2/Reset_AD_BO.jpg';
import th_aspire3rd from './assets/Thumbs/th_aspire3rd.jpg';
import Aspire_Ad_3rdPMF from './assets/Images/Aspire_Ad_3rdPMF.jpg';
import th_aspire36old from './assets/Thumbs/th_aspire36old.jpg';
import Aspire36OldBottle from './assets/Images/Aspire36OldBottle.jpg';
import Aspire_S2 from './assets/Images/Aspire_S2.jpg';
import Aspire_S1 from './assets/Images/Aspire-S1.jpg';
import th_aspire36 from './assets/Thumbs/th_aspire36.jpg';
import Aspire36_label from './assets/Images/Aspire36_label.jpg';
import Aspire36RLiquidMU from './assets/Images/Aspire36RLiquidMU.jpg';
import th_paravolbox from './assets/Thumbs/th_paravolbox.jpg';
import ParavolBoxMockUp from './assets/Images/ParavolBoxMockUp.jpg';
import ParavolBoxMockUp2 from './assets/Images/ParavolBoxMockUp2.jpg';
import th_AspireLite from './assets/Thumbs/th_AspireLite.jpg';
import Aspire_Lite_Label from './assets/Images2/Aspire_Lite_Label.jpg';
import AspireLiteMU3 from './assets/Images2/AspireLiteMU3.jpg';
import th_aspirebrochure2 from './assets/Thumbs/th_aspirebrochure2.jpg';
import Aspire36_BO from './assets/Images4/Aspire36_BO.jpg';
import Aspire36_BI from './assets/Images4/Aspire36_BI.jpg';
import th_AFILogo from './assets/Thumbs/th_AFILogo.jpg';
import Cylapril_AFR from './assets/Images2/Cylapril_AFR.jpg';
import Cylapril_ASER from './assets/Images2/Cylapril_ASER.jpg';
import Cylapril_MV from './assets/Images2/Cylapril_MV.jpg';
import Cylapril_TBC from './assets/Images2/Cylapril_TBC.jpg';
import AFI from './assets/Images2/AFI.jpg';
import th_AspireA_7_x_10 from './assets/Thumbs/th_AspireA_7_x_10.jpg';
import AspireA_7_x_10 from './assets/Images4/AspireA_7_x_10.jpg';
import th_Aspire365_75x10 from './assets/Thumbs/th_Aspire365.75x10.jpg';
import Aspire365_75x10 from './assets/Images4/Aspire365.75x10.jpg';
import th_Aspire36_Charlotte from './assets/Thumbs/th_Aspire36_Charlotte.jpg';
import Aspire36_Charlotte from './assets/Images4/Aspire36_Charlotte.jpg';
import th_CylaprilCreative1 from './assets/Thumbs/th_CylaprilCreative1.jpg';
import CylaprilCreative1 from './assets/Images4/CylaprilCreative1.jpg';
import th_LoneStarAd from './assets/Thumbs/th_LoneStarAd.jpg';
import LoneStarAd from './assets/Images4/LoneStarAd.jpg';
import th_Nanopren_MU from './assets/Thumbs/th_Nanopren_MU.jpg';
import Nanopren_MU from './assets/Images4/Nanopren_MU.jpg';
import Nanopren_Box from './assets/Images4/Nanopren_Box.jpg';
import Nanopren_6 from './assets/Images4/Nanopren-6.jpg';
import th_ParavolAdBW from './assets/Thumbs/th_ParavolAdBW.jpg';
import ParavolAdBW from './assets/Images4/ParavolAdBW.jpg';
import th_Paravol_AdA from './assets/Thumbs/th_Paravol_AdA.jpg';
import Paravol_AdA from './assets/Images4/Paravol_AdA.jpg';
import th_ParavolAdBW2 from './assets/Thumbs/th_ParavolAdBW2.jpg';
import ParavolAdBW2 from './assets/Images4/ParavolAdBW2.jpg';
import th_Paravol_Ad_Vibe from './assets/Thumbs/th_Paravol_Ad_Vibe.jpg';
import Paravol_Ad_Vibe from './assets/Images4/Paravol_Ad_Vibe.jpg';
import th_Paravol_AdF from './assets/Thumbs/th_Paravol_AdF.jpg';
import Paravol_AdF from './assets/Images4/Paravol_AdF.jpg';
import th_Paravol_AdG from './assets/Thumbs/th_Paravol_AdG.jpg';
import Paravol_AdG from './assets/Images4/Paravol_AdG.jpg';
import th_VazoprenAd from './assets/Thumbs/th_VazoprenAd.jpg';
import VazoprenAd1 from './assets/Images4/VazoprenAd1.jpg';
import th_VazoprenAd2 from './assets/Thumbs/th_VazoprenAd2.jpg';
import VazoprenAd2 from './assets/Images4/VazoprenAd2.jpg';
import th_VazoprenMU from './assets/Thumbs/th_VazoprenMU.jpg';
import VazoprenMU from './assets/Images4/VazoprenMU.jpg';
import Vazopren_Label from './assets/Images4/Vazopren_Label.jpg';
import th_VasutraBox from './assets/Thumbs/th_VasutraBox.jpg';
import VasutraBox from './assets/Images4/VasutraBox.jpg';
import vasutra_label from './assets/Images4/vasutra_label.jpg'

 

const printData = [
    { 
        thumbnail: th_MedicFP_CD_Case_01,
        slideImages: [
            MedicFP_9x12x4_2P_Flattened,
            MedicFP_CD_Case_Flattened_Page_1,
            MedicFP_CD_Case_Flattened_Page_2,
            MedicFP_CD_Case_01,
            MedicFP_CD_Label,
        ],
        title: "MedicFP CD Cases",
        text: "MedicFP CD Cases created for MedicFP.  CD Cases used to distribute software and information about MedicFP Products. Created using Adobe Photoshop, Illustrator, and InDesign"
       }, 
       { 
        thumbnail: th_MedicFP_Documentation_Template,
        slideImages: [
            MedicFP_Documentation_Template,
            MedicFP_Documentation_Template2,
            MedicFP_Documentation_Template3,
            MedicFP_Documentation_Template4,
            MedicFP_Documentation_Template5,
        ],
        title: "MedicFP Documentation Template",
        text: "MedicFP Documenation Template.  Used for MedicFP Documentation and Reports. Created using Adobe Photoshop, Illustrator, and Indesign"
       },     
   { 
        thumbnail: th_Leviathan_ad_v2,
        slideImages: [
            Leviathan_ad_v2
        ],
        title: "Leviathan Ad",
        text: "Leviathan Ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress."
       },      
       { 
                  thumbnail: th_LeviathanReloaded,
                  slideImages: [
                      LeviathanReloadedMU,
                      Leviathan_Label_Reloaded
                  ],
                  title: "Leviathan Reloaded",
                  text: "Leviathan Reloaded Bottle and Label for Palo Alto Labs. Sold in Walmart, GNC, and Walgreens. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress" 
             },
                 { 
                  thumbnail: th_cylaprilbox,
                  slideImages: [
                        Cylapriil_Box,
                        Cylapril_Bottle
                  ],
                  title: "Cylapril",
                  text: "Cylapril for Palo Alto Labs. Sold in Walmart, GNC, and Walgreens. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_Paravol_Ad_M,
                  slideImages: [
                      Paravol_Ad_MF
                  ],
                  title: "Paravol Ad",
                  text: "Paravol Ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_FitnessFirstCatalog,
                  slideImages: [
                      FitnessFirstCatalog
                  ],
                  title: "Palo Alto Brochure",
                   text: "Palo Alto Brochure created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_aspirebrochure,
                  slideImages: [
                      _11x17_Outside
                  ],
                  title: "Aspire36 Brochure",
                text: "Aspire 36 Brochure created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
      

             },
                 { 
                  thumbnail: th_leviathanad,
                  slideImages: [
                      LeviathanAd
                  ],
                  title: "Leviathan Ad",
                   text: "Leviathan Ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_Leviathan_Ad_3p,
                  slideImages: [
                      Leviathan_Ad_3p
                  ],
                  title: "Leviathan Ad",
                   text: "Leviathan Ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_Paravol_Ad_3p,
                  slideImages: [
                      Paravol_Ad_3p
                  ],
                  title: "Paravol Ad",
                  text: "Paravol, ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_LeviathanB,
                  slideImages: [
                    LeviathanBO,
                    LeviathanBI
                  ],
                  title: "Leviathan Ad",
                   text: "Leviathan Ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_LeviathanLabel,
                  slideImages: [
                      LeviathanLabel,
                      leviathanbottle
                  ],
                  title: "Leviathan Label",
                  text: "Leviathan Bottle and Label for Palo Alto Labs. Sold in Walmart, GNC, and Walgreens. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_Incarnate_bottle,
                  slideImages: [
                      Incarnate_bottle,
                      IncarnateLabel
                  ],
                  title: "Incarnate",
                  text: "Incarnate Bottle and Label for Palo Alto Labs. Sold in Walmart, GNC, and Walgreens. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"

             },
                 { 
                  thumbnail: th_Incarnate_ad,
                  slideImages: [
                      Incarnate_ad
                  ],
                  title: "Incarnate Ad", 
                  text: "Incarnate Ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
   },
                 { 
        thumbnail: th_ResetBottle,
        slideImages: [
            Reset_Bottle,
            Reset_AD
        ],
        title: "Reset AD", 
        text: "Reset A.D. Ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
   },
       { 
        thumbnail: th_resetbrochure,
        slideImages: [
            Reset_AD_BI,
            Reset_AD_BO
        ],
        title: "Reset AD advertisement", 
        text: "ResetAD Ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
   },
       { 
        thumbnail: th_aspire3rd,
        slideImages: [
            Aspire_Ad_3rdPMF
        ],
        title: "Apire36", 
        text: "Aspire36 Ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
   },
       { 
        thumbnail: th_aspire36old,
        slideImages: [
            Aspire36OldBottle,
            Aspire_S1,
            Aspire_S2
        ],
        title: "Aspire36", 
        text: "Aspire 36 Bottle and Label for Palo Alto Labs. Sold in Walmart, GNC, and Walgreens. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
   },
       { 
        thumbnail: th_aspire36,
        slideImages: [
            Aspire36_label,
            Aspire36RLiquidMU
        ],
        title: "Aspire36 Label",
        text: "Aspire36 Bottle and Label for Palo Alto Labs. Sold in Walmart, GNC, and Walgreens. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
      
  
   },
       { 
        thumbnail: th_paravolbox,
        slideImages: [
            ParavolBoxMockUp,
            ParavolBoxMockUp2
        ],
        title: "Paravol Box", 
        text: "Paravol Box for Palo Alto Labs. Sold in Walmart, GNC, and Walgreens. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
   },
       { 
        thumbnail: th_AspireLite,
        slideImages: [
            Aspire_Lite_Label,
            AspireLiteMU3
        ],
        title: "Aspire Lite",
        text: "Aspire Lite Bottle and Label for Palo Alto Labs. Sold in Walmart, GNC, and Walgreens. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
   },
       { 
        thumbnail: th_aspirebrochure2,
        slideImages: [
            Aspire36_BO,
            Aspire36_BI
        ],
        title: "Aspire36 Brochure",
        text: "Aspire36 Brochure created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
   },
       { 
        thumbnail: th_FitnessFirstCatalog,
        slideImages: [
            FitnessFirstCatalog
        ],
        title: "Fitness First Catalogue",
        text: "Fitness First Catalogue created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
   },
       { 
        thumbnail: th_AFILogo,
        slideImages: [
            Cylapril_AFR,
            Cylapril_ASER,
            Cylapril_MV,
            Cylapril_TBC,
            AFI
        ],
        title: "Cylapril Label",
        text: "Cylapril Bottle and Label for Palo Alto Labs. Sold in Walmart, GNC, and Walgreens. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
   },
       { 
        thumbnail: th_AspireA_7_x_10,
        slideImages: [
            AspireA_7_x_10
        ],
        title: "Aspire36", 
        text: "Aspire36 Ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"

   },
       { 
        thumbnail: th_Aspire365_75x10,
        slideImages: [
            Aspire365_75x10
        ],
        title: "Aspire36 Ad",
         text: "Aspire36 Ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
       },      
       { 
                  thumbnail: th_Aspire36_Charlotte,
                  slideImages: [
                      Aspire36_Charlotte
                  ],
                  title: "Aspire36",
                  text: "Aspire36 Ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_CylaprilCreative1,
                  slideImages: [
                      CylaprilCreative1
                  ],
                  title: "Cylapril Creative", 
                  text: "Cylapril Creative created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_LoneStarAd,
                  slideImages: [
                      LoneStarAd
                  ],
                  title: "Lone Star Palo Alto Ad",
                  text: "Lone Star ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_Nanopren_MU,
                  slideImages: [
                      Nanopren_MU,
                      Nanopren_Box,
                      Nanopren_6
                  ],
                  title: "Nanopren Ad",
                  text: "Nanopren Advertisement created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_ParavolAdBW,
                  slideImages: [
                      ParavolAdBW
                  ],
                  title: "Paravol Ad",
                  text: "Paravol Advertisement created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"

             },
                 { 
                  thumbnail: th_Paravol_AdA,
                  slideImages: [
                      Paravol_AdA
                  ],
                  title: "Paravol Adverstisement",
                  ext: "Paravol created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_ParavolAdBW2,
                  slideImages: [
                      ParavolAdBW2
                  ],
                  title: "Paravol Ad",
                  text: "Paravol Ad created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_Paravol_Ad_Vibe,
                  slideImages: [
                      Paravol_Ad_Vibe
                  ],
                  title: "Paravol Ad Vibe",
                  text: "Paravol Advertisement created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_Paravol_AdF,
                  slideImages: [
                      Paravol_AdF
                  ],
                  title: "Paravol Advertisement",
                  text: "Paravol Advertisement created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_Paravol_AdG,
                  slideImages: [
                      Paravol_AdG
                  ],
                  title: "Paravol Ad",
                  text: "Paravol Advertsisement created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
             },
                 { 
                  thumbnail: th_VazoprenAd,
                  slideImages: [
                      VazoprenAd1
                  ],
                  title: "Vazopren Ad",
                  text: "Vazopren  created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"

             },
                 { 
                  thumbnail: th_VazoprenAd2,
                  slideImages: [
                      VazoprenAd2
                  ],
                  title: "Vazopren Ad",
                  text: "Vazopren  created for Palo Alto Labs.  Used for advertisements for 50+ publications including the Miami Herald, New York Post, Maxim, and Golf Digest. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
   },
                 { 
        thumbnail: th_VazoprenMU,
        slideImages: [
            VazoprenMU,
            Vazopren_Label
        ],
        title: "Vazopren",
        text: "Vazopren Bottle and Label for Palo Alto Labs. Sold in Walmart, GNC, and Walgreens. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
   },
       { 
        thumbnail: th_VasutraBox,
         slideImages: [
             VasutraBox,
             vasutra_label
         ],
         title: "Vasutra Box",
         text: "Vasutra Box and Label for Palo Alto Labs. Sold in Walmart, GNC, and Walgreens. Created using Adobe Photoshop, Illustrator, Indesign, and QuarkXpress"
       }
];

export function PrintWork() {



    
    return Object.entries(printData).map(([key, value], i) => (
        
            
                <DrazticDialog key={key} {...value} />

       
         
     ))





};