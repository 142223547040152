import React from 'react';

import {DrazticImageDialog} from './ImageDialog.js';

import th_THbanner from './assets/Thumbs/th_THbanner.jpg';
import TritonHealthBanner from './assets/Animations/TritonHealthBanner.gif';
import th_PaloAltobanner from './assets/Thumbs/th_PaloAltobanner.jpg';
import banner from './assets/Animations/banner.gif';
import bigbanner from './assets/Animations/bigbanner.gif';

const animationsGifData = [
    {
        thumbnail: th_THbanner,
        image: TritonHealthBanner,
        title:"Triton Health", 
        text:"Triton Health, animated using Adobe Photoshop and Flash"
        },
    {
        thumbnail: th_PaloAltobanner,
        image: banner,
        title:"Palo Alto Labs",
        text:"Palo Alto Labs, animated using Adobe Photoshop and Flash"
        },
    {
        thumbnail: th_PaloAltobanner,
        image: bigbanner, 
        title:"Palo Alto Labs", 
        text:"Palo Alto Labs, animated animated using Adobe Photoshop and Flash"
        },
];

export function AnimationsGif() {



    
    return Object.entries(animationsGifData).map(([key, value], i) => (
        
            
                <DrazticImageDialog key={key} {...value} />

       
         
     ))





};