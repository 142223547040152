import React from 'react';

import {DrazticDialog} from './SlideDialog.js';

import th_phonelogo from './assets/Thumbs/th_phonelogo.jpg';
import phoneLogo from './assets/Images3/phonelogo.jpg';
import th_assure_web_hires_template  from './assets/Thumbs/th_assure_web_hires_template.jpg';
import th_certify_web_hires_template from './assets/Thumbs/th_certify_web_hires_template.jpg';
import th_verify_connect from './assets/Thumbs/th_verify_connect.jpg';
import th_verify_id_safe_sound_secure_white from './assets/Thumbs/th_verify_id_safe_sound_secure_white.jpg';
import th_Verify_Logo_CMYK_shadow from './assets/Thumbs/th_Verify_Logo_Silver_CMYK.jpg'; 
import th_verify_web_hires_template from './assets/Thumbs/th_verify_web_hires_template.jpg';
import th_Paravollogo from './assets/Thumbs/th_Paravollogo.gif';
import th_nextjoblogo from './assets/Thumbs/th_nextjobathomelogo.jpg';
import th_Leviathan_ReloadedLogo from './assets/Thumbs/th_Leviathan_Reloadedlogo.gif';
import th_NewIncarnateLogo from './assets/Thumbs/th_NewIncarnateLogo.gif';
import th_NewResetLogo from './assets/Thumbs/th_NewResetLogo.gif';
import th_Aspire36Logo from './assets/Thumbs/th_Aspire36Logo.gif';
import th_Cylapril_Logo from './assets/Thumbs/th_Cylapril_Logo.gif';
import th_NutrasciLogo1 from './assets/Thumbs/th_NutraSciLogo1.jpg';
import th_NutrasciLogo2 from './assets/Thumbs/th_NutrasciLogo2.jpg';
import th_NutrasciLogo3 from './assets/Thumbs/th_NutrasciLogo3.jpg';
import th_NutrasciLogo4 from './assets/Thumbs/th_NutrasciLogo4.jpg';
import th_NutrasciLogo5 from './assets/Thumbs/th_NutrasciLogo5.jpg';
import th_NutrasciLogo6 from './assets/Thumbs/th_NutrasciLogo6.jpg';
import th_PaloAltoLogo from './assets/Thumbs/th_PaloAltoLogo.jpg';
import th_afilogo2 from './assets/Thumbs/th_afilogo2.gif';
import th_afilogo3 from './assets/Thumbs/th_afilogo3.gif';
import th_afilogo4 from './assets/Thumbs/th_afilogo4.gif';
import th_vasutralogo from './assets/Thumbs/th_vasutralogo.gif'; 
import th_Vazoprenlogo from './assets/Thumbs/th_Vazoprenlogo.gif';
import th_AspireLiteLogo from './assets/Thumbs/th_AspireLiteLogo.gif';
import th_CapeBurlingtonlogo from './assets/Thumbs/th_capeburlingtonlogo.gif';
import th_mommalogo from './assets/Thumbs/th_mommalogo.gif';
import th_vectracapitallogo from './assets/Thumbs/th_vectracapitallogo.gif';
import th_afilogo from './assets/Thumbs/th_afilogo.gif'; 
import th_vectralogo2 from './assets/Thumbs/th_vectralogo2.gif';
import th_VectraVestLogo from './assets/Thumbs/th_VectraVestLogo.gif';
import th_WFLogo from './assets/Thumbs/th_WFLogo.gif';
import th_EquinasCorp from './assets/Thumbs/th_equinascorp.gif';
import th_FirstCirclelogo from './assets/Thumbs/th_FirstCirclelogo.gif';
import th_ConstructiveConsciousness from './assets/Thumbs/th_constructiveconsciousness.gif';
import th_cdlogo from './assets/Thumbs/th_cdlogo.gif';
import th_cdf from './assets/Thumbs/th_cdf.gif';
import th_CommercialFoodSupplies from './assets/Thumbs/th_CommercialFoodSupplies.gif';
import th_AFALogo from './assets/Thumbs/th_AFAlogo.gif';
import th_AfricanOpportunity from './assets/Thumbs/th_africanopportunity.gif';
import th_CapePointEntertainmentlogo from './assets/Thumbs/th_CapePointEntertainmentlogo.jpg';
import th_dxgloballogo from './assets/Thumbs/th_dxgloballogo.gif';
import th_homerescue from './assets/Thumbs/th_homerescue.gif';
import th_newlitecorp_logo from './assets/Thumbs/th_newlitecorplogo.gif';
import th_SystemsandSignals from './assets/Thumbs/th_systemsandsignalslogo.gif';
import th_turnkeyfunds from './assets/Thumbs/th_turnkeyfunds.gif';

import thumbnail_assure_web_hires_template from './assets/Images5/thumbnail_assure_web_hires_template.png';
import thumbnail_certify_web_hires_template from './assets/Images5/thumbnail_certify_web_hires_template.png';
import thumbnail_verify_connect from './assets/Images5/thumbnail_verify_connect.png';
import thumbnail_verify_id_safe_sound_secure_white from './assets/Images5/thumbnail_verify_id_safe_sound_secure_white.png';
import thumbnail_Verify_Logo_CMYK_shadow from './assets/Images5/thumbnail_Verify_Logo_CMYK_shadow.png';
import thumbnail_Verify_Logo_Silver_CMYK from './assets/Images5/thumbnail_Verify_Logo_Silver_CMYK.png';
import thumbnail_verify_web_hires_template from './assets/Images5/thumbnail_verify_web_hires_template.png';
import Paravollogo from './assets/Images3/Paravollogo.gif';
import nextjoblogo from './assets/Images5/nextjoblogo.jpg';
import Leviathan_ReloadedLogo from './assets/Images3/Leviathan_ReloadedLogo.gif';
import NewIncarnateLogo from './assets/Images3/NewIncarnateLogo.gif';
import NewResetLogo from './assets/Images3/NewResetLogo.gif';
import Aspire36Logo from './assets/Images3/Aspire36Logo.gif';
import Cylapril_Logo from './assets/Images3/Cylapril_Logo.gif';
import NutrasciLogo1 from './assets/Images3/NutrasciLogo1.jpg';
import NutrasciLogo2 from './assets/Images3/NutrasciLogo2.jpg';
import NutrasciLogo3 from './assets/Images3/NutrasciLogo3.jpg';
import NutrasciLogo4 from './assets/Images3/NutrasciLogo4.jpg';
import NutrasciLogo5 from './assets/Images3/NutrasciLogo5.jpg';
import NutrasciLogo6 from './assets/Images3/NutrasciLogo6.jpg';
import PaloAltoLogo from './assets/Images3/PaloAltoLogo.jpg';
import afilogo2 from './assets/Images3/afilogo2.gif';
import afilogo3 from './assets/Images3/afilogo3.gif';
import afilogo4 from './assets/Images3/afilogo4.gif';
import vasutralogo from './assets/Images3/vasutralogo.gif';
import Vazoprenlogo from './assets/Images3/Vazoprenlogo.gif';
import AspireLiteLogo from './assets/Images3/AspireLiteLogo.gif';
import CapeBurlingtonlogo from './assets/Images3/CapeBurlingtonlogo.gif';
import mommalogo from './assets/Images3/mommalogo.gif';
import vectracapitallogo from './assets/Images3/vectracapitallogo.gif';
import afilogo from './assets/Images3/afilogo.gif';
import vectralogo2 from './assets/Images3/vectralogo2.gif';
import VectraVestLogo from './assets/Images3/VectraVestLogo.gif'
import WFLogo from './assets/Images3/WFLogo.gif';
import EquinasCorp from './assets/Images3/EquinasCorp.gif';
import FirstCirclelogo from './assets/Images3/FirstCirclelogo.gif';
import ConstructiveConsciousness from './assets/Images3/ConstructiveConsciousness.gif';
import cdlogo from './assets/Images3/cdlogo.gif';
import cdf from './assets/Images3/cdf.gif';
import CommercialFoodSupplies from './assets/Images3/CommercialFoodSupplies.jpg';
import AFALogo from './assets/Images3/AFALogo.gif';
import AfricanOpportunity from './assets/Images3/AfricanOpportunity.gif';
import CapePointEntertainmentlogo from './assets/Images3/CapePointEntertainmentlogo.jpg';
import dxgloballogo from './assets/Images3/dxgloballogo.gif';
import homerescue from './assets/Images3/homerescue.gif';
import newlitecorp_logo from './assets/Images3/newlitecorp_logo.gif';
import SystemsandSignals from './assets/Images3/SystemsandSignals.gif';
import turnkeyfunds from './assets/Images3/turnkeyfunds.gif';

const logoData = [
    {
        text: "MedicFP Phone Logo, designed in Adobe Photoshop and Illustrator",
        title: "MedicFP Phone Logo",
        thumbnail: th_phonelogo,
        slideImages: [
            phoneLogo
           ]
   },
    {
         text: "Assure Icon, designed in Adobe Photoshop and Illustrator",
         title: "Assure Icon",
         thumbnail: th_assure_web_hires_template,
         slideImages: [
             thumbnail_assure_web_hires_template
            ]
    },
{
     text: "Certify Icon, designed in Adobe Photoshop and Illustrator",
     title: "Certify Icon",
     thumbnail: th_certify_web_hires_template,
     slideImages: [
         thumbnail_certify_web_hires_template
        ]
},
{
     text: "Verify Connect Logo, designed in Adobe Photoshop and Illustrator",
      title: "Verify Connect Logo",
      thumbnail: th_verify_connect,
      slideImages: [
          thumbnail_verify_connect
        ]
},
{
     text: "Verify ID Logo Brand, designed in Adobe Photoshop and Illustrator",
     title: "Verify Logo Brand",
     thumbnail: th_verify_id_safe_sound_secure_white,
     slideImages: [
         thumbnail_verify_id_safe_sound_secure_white
     ]
},

{
     text: "Verify Logo CMYK and Silver, designed in Adobe Photoshop and Illustrator",
     title: "Verify Logo CMYK and Silver",
     thumbnail: th_Verify_Logo_CMYK_shadow,
     slideImages: [
         thumbnail_Verify_Logo_CMYK_shadow,
         thumbnail_Verify_Logo_Silver_CMYK
     ]
}, 
{
     text: "Verify ID Icon, designed in Adobe Photoshop and Illustrator",
    title: "Verify ID Icon",
    thumbnail: th_verify_web_hires_template,
    slideImages: [
        thumbnail_verify_web_hires_template
    ]
},
{
     text: "Paravol Logo, designed in Adobe Photoshop and Illustrator",
     title: "Paravol",
     thumbnail: th_Paravollogo,
     slideImages: [
         Paravollogo
     ]
 },
 {
     title: "NextJobAtHome",
     text: "Next Job At Home Logo, designed in Adobe Photoshop and Illustrator",
     thumbnail: th_nextjoblogo,
     slideImages: [
         nextjoblogo
     ]
 },
 {
     title: "Leviathan Reloaded",
     text: "Leviathan Reloaded Logo, designed in Adobe Photoshop and Illustrator",
     thumbnail: th_Leviathan_ReloadedLogo,
     slideImages: [
         Leviathan_ReloadedLogo
     ]
 },
 {
      title: "Incarnate Logo",
      text: "Incarnate Logo, designed in Adobe Photoshop and Illustrator",
      thumbnail: th_NewIncarnateLogo,
      slideImages: [
          NewIncarnateLogo
      ]
              },
              {
                   title: "Reset A.D.",
                   text: "Reset A.D. logo, designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_NewResetLogo,
                   slideImages: [
                       NewResetLogo
                   ]
              },
              {
                   title: "Aspire36",
                   text: "Aspire36 logo, designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_Aspire36Logo,
                   slideImages: [
                       Aspire36Logo
                   ]
              },
        

              {
                   title: "Cylapril",
                   text: "Cylapril logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_Cylapril_Logo,
                   slideImages: [
                       Cylapril_Logo
                   ]
              },
              {
                   title: "Nutra Sci Logo Ver. 1",
                   text: "Nutra Sci Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_NutrasciLogo1,
                   slideImages: [
                       NutrasciLogo1
                   ]
              },
              {
                   title: "Nutra Sci Logo Ver. 2",
                   text: "Nutra Sci Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_NutrasciLogo2,
                   slideImages: [
                       NutrasciLogo2
                   ]
              },
              {
                   title: "Nutra Sci Logo Ver. 3",
                   text: "Nutra Sci Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_NutrasciLogo3,
                   slideImages: [
                       NutrasciLogo3
                   ]
              },
              {
                   title: "Nutra Sci Logo Ver. 4",
                   text: "Nutra Sci Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_NutrasciLogo4,
                   slideImages: [
                       NutrasciLogo4
                   ]
              },
              {
                   title: "Nutra Sci Logo Ver. 5",
                   text: "Nutra Sci Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_NutrasciLogo5,
                   slideImages: [
                       NutrasciLogo5
                   ]
              },
        
  
    {
         title: "Nutra Sci Logo Ver. 6",
         text: "Nutra Sci Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_NutrasciLogo6,
         slideImages: [
                       NutrasciLogo6
                   ]
    },
    {
         title: "Palo Alto Labs",
         text: "Palo Alto Labs Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_PaloAltoLogo,
         slideImages: [
             PaloAltoLogo
         ]
    },
    {
         title: "AFI Logo Ver. 2",
         text: "Adrenal Fatigue Institute Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_afilogo2,
         slideImages: [
             afilogo2
         ]
    },
    {
         title: "AFI Logo Ver. 3",
         text: "Adrenal Fatigue Institute Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_afilogo3,
         slideImages: [
             afilogo3
         ]
    },
    {
         title: "AFI Logo Ver. 4",
         text:"Adrenal Fatigue Institute Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_afilogo4,
         slideImages: [
             afilogo4
         ]
    },
    {
         title: "Vasutra", 
         text: "Vasutra Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_vasutralogo,
         slideImages: [
             vasutralogo
         ]
    },
  
  
    {
         title: "Vazopren", 
         text: "Vazopren designed in Adobe Photoshop and Illustrator",
         thumbnail: th_Vazoprenlogo,
         slideImages: [
             Vazoprenlogo
         ]
    },
    {
         title: "Aspire Lite", 
         text: "Aspire Lite Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_AspireLiteLogo,
         slideImages: [
             AspireLiteLogo
         ]
    },
    {
         title: "Cape Burlington", 
         text: "Cape Burlington Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_CapeBurlingtonlogo,
         slideImages: [
             CapeBurlingtonlogo
         ]
    },
    {
         title: "M.O.M.M.A.", 
         text: "MOMMA Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_mommalogo,
         slideImages: [
             mommalogo
         ]
    },
    {
         title: "Vectra Capital Corp Logo Ver. 1", 
         text: "Vectra Capital Corp Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_vectracapitallogo,
         slideImages: [
             vectracapitallogo
         ]
    },
    {
         title: "AFI Logo Ver. 1", 
         text: "Adrenal Fatigue Institute Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_afilogo,
         slideImages: [
             afilogo
         ]
    },
    {
         title: "Vectra Capital Corp Logo Ver. 2", 
         text: "Vectra Capital Corp Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_vectralogo2,
         slideImages: [
             vectralogo2
         ]
              },
              {
                   title: "Vectra Vest", 
                   text: "Vectra Vest Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_VectraVestLogo,
                   slideImages: [
                       VectraVestLogo
                   ]
              },
              {
                   title: "The Way Forward Foundation", 
                   text: "TWFF Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_WFLogo,
                   slideImages: [
                       WFLogo
                   ]
              },
              {
                   title: "Equinas", 
                   text: "Equinas Corp Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_EquinasCorp,
                   slideImages: [
                       EquinasCorp
                   ]
              },
              {
                   title: "First Circle Capital", 
                   text: "First Circle Capital Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_FirstCirclelogo,
                   slideImages: [
                       FirstCirclelogo
                   ]
              },
              {
                   title: "Constructive Consciousness", 
                   text: "Constructive Consciousness Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_ConstructiveConsciousness,
                   slideImages: [
                       ConstructiveConsciousness
                   ]
              },
              {
                   title: "Consciousness Development", 
                   text: "Consciousness Development Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_cdlogo,
                   slideImages: [
                       cdlogo
                   ]
              },
              {
                   title: "Consciousness Development Foundation", 
                   text: "Consciousness Development Foundation Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_cdf,
                   slideImages: [
                       cdf
                   ]
              },
              {
                   title: "Commercial Food Supplies", 
                   text: "Commercial Food Supplies Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_CommercialFoodSupplies,
                   slideImages: [
                       CommercialFoodSupplies
                   ]
              },
              {
                   title: "Africa For All", 
                   text: "AFA Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_AFALogo,
                   slideImages: [
                       AFALogo
                   ]
              },
              {
                   title: "African Opportunity", 
                   text: "African Opportunity Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_AfricanOpportunity,
                   slideImages: [
                       AfricanOpportunity
                   ]
              },
              {
                   title: "Cape Point Entertainment", 
                   text: "CPE Logo designed in Adobe Photoshop and Illustrator",
                   thumbnail: th_CapePointEntertainmentlogo,
                   slideImages: [
                       CapePointEntertainmentlogo
                   ]
              },
        
  
    {
         title: "DX Global", 
         text: "DX Global Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_dxgloballogo,
         slideImages: [
             dxgloballogo
         ]
    },
    {
         title: "Home Owners Rescue Plan", 
         text: "Home Owners Rescue Plan Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_homerescue,
         slideImages: [
             homerescue
         ]
    },
    {
         title: "New Lite Corp", 
         text: "New Lite Corp Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_newlitecorp_logo,
         slideImages: [
             newlitecorp_logo
         ]
    },
    {
         title: "Systems and Signals Cafe", 
         text: "Systems and Signals Cafe Logo designed in Adobe Photoshop and Illustrator",
         thumbnail: th_SystemsandSignals,
         slideImages: [
             SystemsandSignals
         ]
    },
    {
         text: "Turnkey Funds Logo designed in Adobe Photoshop and Illustrator",
         title: "Turnkey Funds",
         thumbnail: th_turnkeyfunds,
         slideImages: [
             turnkeyfunds
         ]
    },
]


export function Logos() {

    
     return Object.entries(logoData).map(([key, value], i) => (
            
                
                    <DrazticDialog key={key} {...value} />

               
             
    ))

    

    
};