import React from 'react';

import {DrazticDialog} from './SlideDialog.js';

import th_markerhero from './assets/Thumbs/th_markerhero.jpg';
import markerhero from './assets/Images5/markerhero.jpg';
import th_BackgroundLayout from './assets/Thumbs/th_BackgroundLayout.jpg';
import BackgroundLayout from './assets/Images5/BackgroundLayout.jpg';
import th_PirateModelSheet from './assets/Thumbs/th_PirateModelSheet.jpg';
import PirateModelSheet from './assets/Images5/PirateModelSheet.jpg'
import th_ColonialBackground from './assets/Thumbs/th_ColonialBackground.jpg';
import ColonialBackground from './assets/Images5/ColonialBackground.JPG';
import th_WormViewLayout from './assets/Thumbs/th_WormViewLayout.jpg';
import WormViewLayout from './assets/Images2/WormViewLayout.jpg';
import th_PirateMouse1 from './assets/Thumbs/th_PirateMouse1.jpg';
import PirateMouse1 from './assets/Images2/PirateMouse1.jpg';
import PirateMouse2 from './assets/Images2/PirateMouse2.jpg';
import th_DSC01390 from './assets/Thumbs/th_DSC01390.jpg';
import DSC01390 from './assets/Images2/DSC01390.JPG'; 
import DSC01391 from './assets/Images2/DSC01391.JPG'; 
import DSC01392 from './assets/Images2/DSC01392.JPG'; 
import DSC01393 from './assets/Images2/DSC01393.JPG'; 
import DSC01394 from './assets/Images2/DSC01394.JPG';
import DSC01396 from './assets/Images2/DSC01396.JPG';
import th_BlueLayout2PanelSpread from './assets/Thumbs/th_BlueLayout2PanelSpread.jpg';
import BlueLayout2PanelSpread from './assets/Images2/BlueLayout2PanelSpread.jpg';
import th_SamuranianFinal from './assets/Thumbs/th_SamuranianFinal.jpg';
import SamuranianFinal from './assets/Images2/SamuranianFinal.jpg';
import th_DetailRobotFinal from './assets/Thumbs/th_DetailRobotFinal.jpg';
import DetailRobotFinal from './assets/Images2/DetailRobotFinal.jpg';
import th_CasualWoman from './assets/Thumbs/th_CasualWoman.jpg';
import CasualWoman from './assets/Images2/CasualWoman.jpg';
import th_FlyingZ from './assets/Thumbs/th_FlyingZ.jpg';
import FlyingZ from './assets/Images2/FlyingZ.jpg';
import th_HeadCoppin from './assets/Thumbs/th_HeadCoppin.jpg';
import HeadCoppin from './assets/Images2/HeadCoppin.jpg';
import th_DaguModelSheetColor from './assets/Thumbs/th_DaguModelSheetColor.jpg';
import DaguModelSheetColor from './assets/Images2/DaguModelSheetColor.jpg';
import th_KydraModelSheetColor from './assets/Thumbs/th_KydraModelSheetColor.jpg';
import KydraModelSheetColor from './assets/Images2/KydraModelSheetColor.jpg';
import th_DaguKydraTorekRura from './assets/Thumbs/th_DaguKydraTorekRura.jpg';
import DaguKydraTorekRura from './assets/Images2/DaguKydraTorekRura.jpg';
import th_SoulGroup from './assets/Thumbs/th_SoulGroup.jpg';
import SoulGroup from './assets/Images/SoulGroup.jpg';
import SoulProgress1 from './assets/Images/SoulProgress1.jpg';
import SoulProgress2 from './assets/Images/SoulProgress2.jpg';
import SoulProgress3 from './assets/Images/SoulProgress3.jpg';
import th_CPoseFinal from './assets/Thumbs/th_CPoseFinal.jpg';
import CPoseFinal from './assets/Images/CPoseFinal.jpg';
import th_LPoseFinal from './assets/Thumbs/th_LPoseFinal.jpg';
import LPoseFinal from './assets/Images/LPoseFinal.jpg';
import th_KPoseFinal from './assets/Thumbs/th_KPoseFinal.jpg';
import KPoseFinal from './assets/Images/KPoseFinal.jpg';
import th_BPoseFinal from './assets/Thumbs/th_BPoseFinal.jpg';
import BPoseFinal from './assets/Images/BPoseFinal.jpg';
import th_NPoseFinal from './assets/Thumbs/th_NPoseFinal.jpg';
import NPoseFinal from './assets/Images/NPoseFinal.jpg';
import th_GroupPoseFinal from './assets/Thumbs/th_GroupPoseFinal.jpg';
import GroupPoseFinal from './assets/Images/GroupPoseFinal.jpg';
import th_GroupPose2 from './assets/Thumbs/th_GroupPose2.jpg';
import GroupPose2 from './assets/Images/GroupPose2.jpg';
import c1d from './assets/Images/c1d.jpg';
import c1a from './assets/Images/c1a.jpg';
import c1b from './assets/Images/c1b.jpg';
import c1c from './assets/Images/c1c.jpg';
import c1e from './assets/Images/c1e.jpg';


const illustrationData = [
{
    thumbnail: th_markerhero,
    slideImages: [
        markerhero
    ],
    title: "Marker Hero", 
    text: "Marker Hero by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_BackgroundLayout,
    slideImages: [
        BackgroundLayout
    ],
    title: "Background Layout",
     text: "Background Layout by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_PirateModelSheet,
    slideImages: [
        PirateModelSheet
    ],
    title: "Pirate Model Sheet", 
    text: "Pirate Model Sheet by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_ColonialBackground,
    slideImages: [
        ColonialBackground
    ],
    title: "Colonial Background", 
    text: "Colonial Background by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_WormViewLayout,
    slideImages: [
        WormViewLayout
    ],
    title: "Worm View Layout",
    text: "Worm View Layout by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_PirateMouse1,
    slideImages: [
        PirateMouse1,
        PirateMouse2
    ],
    title: "Pirate Mouse",
    text: "Pirate Mouse by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_DSC01390,
    slideImages: [
        DSC01390,
        DSC01391, 
        DSC01392, 
        DSC01393, 
        DSC01394, 
        DSC01396
    ],
    title: "Clay Mouse", 
    text: "Clay Mouse by DraZtic, created using pencil, pen, and Adobe Photoshop"
}, 
{
    thumbnail: th_BlueLayout2PanelSpread,
    slideImages: [
        BlueLayout2PanelSpread
    ],
    title: "Blue Layout", 
    text: "Blue Layout by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_SamuranianFinal,
    slideImages: [
        SamuranianFinal
    ],
    title: "Samuranian", 
    text: "Samuranian by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_DetailRobotFinal,
    slideImages: [
        DetailRobotFinal
    ],
    title: "Robot Final", 
    text: "Robot Final by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_CasualWoman,
    slideImages: [
        CasualWoman
    ],
    title: "Casual Woman", 
    text: "Casual Woman by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_FlyingZ,
    slideImages: [
        FlyingZ
    ],
    title: "Flying Z", 
    text: "Flying Z by DraZtic, created using pencil, pen, and Adobe Photoshop"
},


{
    thumbnail: th_HeadCoppin,
    slideImages: [
        HeadCoppin
    ],
    title: "Head Choppin", 
    text: "Head Choppin by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_DaguModelSheetColor,
    slideImages: [
        DaguModelSheetColor
    ],
    title: "Dagu Model Sheet", 
    text: "Dagu Model Sheet by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_KydraModelSheetColor,
    slideImages: [
        KydraModelSheetColor
    ],
    title: "Kydra Model Sheet", 
    text: "Kydra Model Sheet by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_DaguKydraTorekRura,
    slideImages: [
        DaguKydraTorekRura
    ],
    title: "Dagu and Friends", 
    text: "Dagu and Friends by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_SoulGroup,
    slideImages: [
        SoulGroup,
        SoulProgress1, 
        SoulProgress2, 
        SoulProgress3 
    ],
    title: "Kurohog Soul", 
    text: "Kurohog Soul by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_CPoseFinal,
    slideImages: [
        CPoseFinal
    ],
    title: "C Pose", 
    text: "C Pose by DraZtic, created using pencil, pen, and Adobe Photoshop"
},


{
    thumbnail: th_LPoseFinal,
    slideImages: [
        LPoseFinal
    ],
    title: "L Pose", 
    text: "L Pose by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_KPoseFinal,
    slideImages: [
        KPoseFinal
    ],
    title: "K Pose", 
    text: "K Pose by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_BPoseFinal,
    slideImages: [
        BPoseFinal
    ],
    title: "B Pose", 
    text: "B Pose by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_NPoseFinal,
    slideImages: [
        NPoseFinal
    ],
    title: "N Pose", 
    text: "N Pose by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_GroupPoseFinal,
    slideImages: [
        GroupPoseFinal
    ],
    title: "C Group Pose", 
    text: "C Group Pose by DraZtic, created using pencil, pen, and Adobe Photoshop"
},
{
    thumbnail: th_GroupPose2,
    slideImages: [
        GroupPose2,
        c1d, 
        c1a, 
        c1b, 
        c1c, 
        c1e 
    ],
    title: "C Group Pose 2", 
    text: "C Group Pose 2 by DraZtic, created using pencil, pen, and Adobe Photoshop"

}
]


export function Illustrations() {

    
    return Object.entries(illustrationData).map(([key, value], i) => (
           
               
                   <DrazticDialog key={key} {...value} />

              
            
   ))

   

   
};